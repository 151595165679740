import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../CSS/Loading.css";
export default function SMSCampainMenagment({
  e,
  all,
  nl,
  left,
  func1,
  func2,
  func3,
  sender,
}) {
  const URL = useSelector((e) => e.UserReducer.url);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const RestaurantCode = useSelector(
    (e) => e.UserReducer.allData.restaurant_info.code
  );
  const [Active, setActive] = useState(true);
  const [Opt, setOpt] = useState("");
  const [Display, setDisplay] = useState("none");
  const [DisplayError, setDisplayError] = useState("none");
  const [DisplayLoading, setDisplayLoading] = useState("none");
  useEffect(() => {
    if (nl * Math.ceil(e.text.length / 160) <= left && e.status === "0") {
      setActive(false);
    } else if (Math.ceil(e.text.length / 160) >= left && e.status === "0") {
      setActive(true);
      setOpt("NoSMS");
    } else if (e.status === "1") {
      setActive(true);
      setOpt("Done");
    }
  }, [nl, left, e, all]);

  const finishCampaign = () => {
    setDisplayLoading("flex");
    //console.log(e);
    axios
      .get(`${URL}/sqlite/send_sms_campaign.php/?id=${e.id}&sender=${Sender}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setDisplayLoading("none");
          setDisplay("flex");
          setTimeout(function () {
            func1();
            func2();
            func3();
          }, 1500);
        } else {
          setDisplayLoading("none");
          setDisplay("none");
          setDisplayError("flex");
          setTimeout(function () {
            setDisplayError("none");
            func1();
            func2();
            func3();
          }, 1500);
        }
      })

      .catch((err) => {
        //console.log(err.response);
        setDisplayLoading("none");
        setDisplay("none");
        setDisplayError("flex");
        setTimeout(function () {
          setDisplayError("none");
          func1();
          func2();
          func3();
        }, 1500);
      });
  };
  const deleteCampaign = () => {
    axios
      .get(
        `${URL}/sqlite/delete_sms_campaign.php/?id=${e.id}`,

        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          func3();
          func1();
          func2();
        }
      })

      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <div className="col-12 my-3">
      <div className="row mx-0 px-0">
        <div className="col-md-3 col-12 py-2 Bold border-left border-top border-bottom">
          {e.name}
        </div>
        <div className="col-md-2 col-12 py-2 border-left border-top border-bottom">
          {Math.ceil(e.text.length / 160) * nl} SMS
        </div>
        <div className=" col-md-4 col-sm-6 col-12 py-2 border-left border-top border-bottom">
          {e.text}
        </div>
        <div className="col-md-3 col-sm-6 col-12 py-2 border text-center position-relative ">
          <button
            type="button"
            class={
              Active
                ? `btn btn-success mb-0 d-none`
                : `btn btn-success mb-0 d-inline`
            }
            disabled={Active}
            onClick={() => finishCampaign()}
          >
            Zrealizuj kamapnię
          </button>
          {Opt === "Done" ? (
            <div class="alert alert-success" role="alert">
              Kampania zrealizowana
            </div>
          ) : (
            ""
          )}
          {Opt === "NoSMS" ? (
            <div class="alert alert-danger mb-0" role="alert">
              Za mało SMS by zrealizowac kampanie. Dokup SMS
            </div>
          ) : (
            ""
          )}
          <button
            type="button"
            class="btn btn-danger my-2 d-inline"
            onClick={() => deleteCampaign()}
          >
            Usuń kamapnię
          </button>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "green",
              display: Display,
            }}
            className=" justify-content-center align-items-center Bold text-white"
          >
            Wysłano
          </div>
          <div
            style={{
              background: "white",
              display: DisplayLoading,
            }}
            className=" justify-content-center align-items-center Bold text-white text-left"
          >
            <div
              className="bodyLoad w-100"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                background: "white",
                display: DisplayLoading,
              }}
            >
              <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "red",
              display: DisplayError,
            }}
            className=" justify-content-center align-items-center Bold text-white"
          >
            Błąd! Spróbuj ponownie póżniej
          </div>
        </div>
      </div>
    </div>
  );
}
