import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import { useSelector } from "react-redux";
import CostumerInfo from "./CostumerInfo";
export default function CustomerManagement() {
  const URL = useSelector((e) => e.UserReducer.url);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [Data, setData] = useState([]);
  useEffect(() => {
    all_consumer_data();
  }, []);
  const all_consumer_data = () => {
    axios
      .get(`${URL}/sqlite/get_marketing_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
       // console.log(response);
        setData(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row mx-0 px-0">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Dane klienów </h1>
                <h6
                  className="upper lite
                "
                >
                  którzy zaakcepotwali zgode marketingową
                </h6>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-12 my-3 text-center">
                {Data.map((element) => (
                  <CostumerInfo e={element} func={all_consumer_data} />
                ))}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
