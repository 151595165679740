import React from "react";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
function Customize({ history }) {
  const redirectDeliveryPleaces = () => {
    history.push("/customize/delivery");
  };
  const redirectTexts = () => {
    history.push("/customize/texts");
  };
  const redirectStyle = () => {
    history.push("/customize/style");
  };
  const redirectCompanyInfo = () => {
    history.push("/customize/companyinfo");
  };
  const redirectIcons = () => {
    history.push("/customize/icons");
  };
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Personaluzj</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button "
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectTexts}
                >
                  teksty
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectStyle}
                >
                  wygląd
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectCompanyInfo}
                >
                  dane firmy
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectIcons}
                >
                  legenda
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectDeliveryPleaces}
                >
                  strefy dostaw
                </button>
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
}

export default withRouter(Customize);
