import React, { useEffect, useState } from "react";
import Header from "./Header";
import FileBase64 from "react-file-base64";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export default function IconNames() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [Icons_legend_title, setIcons_legend_title] = useState("");
  const [Icon_1_title, setIcon_1_title] = useState("");
  const [Icon_2_title, setIcon_2_title] = useState("");
  const [Icon_3_title, setIcon_3_title] = useState("");
  const [Icon_4_title, setIcon_4_title] = useState("");
  const [Icon_5_title, setIcon_5_title] = useState("");
  const [Icon_6_title, setIcon_6_title] = useState("");
  const [Img1, setImg1] = useState("");
  const [Img2, setImg2] = useState("");
  const [Img3, setImg3] = useState("");
  const [Img4, setImg4] = useState("");
  const [Img5, setImg5] = useState("");
  const [Img6, setImg6] = useState("");
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setIcons_legend_title(UserInfo.editable_texts.icons_legend_title);
      setIcon_1_title(UserInfo.editable_texts.icon_1_title);
      setIcon_2_title(UserInfo.editable_texts.icon_2_title);
      setIcon_3_title(UserInfo.editable_texts.icon_3_title);
      setIcon_4_title(UserInfo.editable_texts.icon_4_title);
      setIcon_5_title(UserInfo.editable_texts.icon_5_title);
      setIcon_6_title(UserInfo.editable_texts.icon_6_title);
    }
    if (UserInfo.img !== undefined) {
      setImg1(UserInfo.img.icon1);
      setImg2(UserInfo.img.icon2);
      setImg3(UserInfo.img.icon3);
      setImg4(UserInfo.img.icon4);
      setImg5(UserInfo.img.icon5);
      setImg6(UserInfo.img.icon6);
    }
  }, [UserInfo]);

  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  const setWebsiteData = () => {
    let json = {
      editable_texts: {
        icons_legend_title: Icons_legend_title,
        icon_1_title: Icon_1_title,
        icon_2_title: Icon_2_title,
        icon_3_title: Icon_3_title,
        icon_4_title: Icon_4_title,
        icon_5_title: Icon_5_title,
        icon_6_title: Icon_6_title,
      },
    };

    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Legenda</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Nazwa legendy</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Icons_legend_title}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setIcons_legend_title(e.target.value);
                    }}
                  />
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img1}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_1_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_1_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img2}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_2_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_2_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img3}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_3_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_3_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img4}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_4_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_4_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img5}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_5_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_5_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-2 px-0  mx-0">
                    {" "}
                    <img
                      src={URL + Img6}
                      style={{
                        width: "37px",
                      }}
                    />
                  </div>
                  <div className="col-10 px-0  mx-0">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={Icon_6_title}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        onChange={(e) => {
                          setIcon_6_title(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-success mx-2 mt-3"
              onClick={() => setWebsiteData()}
              disabled={!Active}
            >
              {Text}
            </button>
            <p className=" mx-2">{ComunicateText}</p>
          </>
        </div>{" "}
      </div>
    </>
  );
}
