import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
export default function CategoryONOFF({ id, name, is_active }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const [NOOFF, setNOOFF] = useState(null);
  const [NOOFF_BIT, setNOOFF_BIT] = useState(null);
  const [ButtonType, setButtonType] = useState("btn-danger");
  useEffect(() => {
    if (is_active) {
      setNOOFF("Ukryj");
      setNOOFF_BIT(0)
      setButtonType("btn-danger");
    } else if (!is_active) {
      setNOOFF("Pokaż");
      setNOOFF_BIT(1)
      setButtonType("btn-success");
    }
  }, []);

  const [Active, setActive] = useState(true);
  const OnOFF = (id) => {
    setActive(false);
    if (NOOFF_BIT === 0) {
      let json = {
        name: name,
        is_active: false,
      };
      axios
        .put(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_category/${id}`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200 && response.statusText === "OK") {
            //console.log(response);
            setNOOFF("Pokaż");
            setNOOFF_BIT(1);
            setButtonType("btn-success");
            setActive(true);
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
        });
    } else if (NOOFF_BIT === 1) {
      setActive(false);
      let json = {
        name: name,
        is_active: true,
      };
      axios
        .put(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_category/${id}`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200 && response.statusText === "OK") {
            //console.log(response);
            setActive(true);
            setNOOFF("Ukryj");
            setNOOFF_BIT(0);
            
            setButtonType("btn-danger");
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
        });
    }
  };

  return (
    <button
      type="button"
      class={`btn px-1 py-1 fs3 ${ButtonType} ml-1`}
      onClick={() => OnOFF(id)}
      disabled={!Active}
    >
      {NOOFF}
    </button>
  );
}
