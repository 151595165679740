import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
export default function CostumerInfo({ e, func }) {
  const URL = useSelector((e) => e.UserReducer.url);
  const deleteCostumer = (id) => {
    axios
      .get(`${URL}/sqlite/delete_marketing_permit.php/?phone_number=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if ((response.status = 200)) {
         // console.log(response);
          func();
        }
      })

      .catch((err) => {
      //  console.log(err.response);
      });
  };
  return (
    <div className="col-12 my-lg-1 my-5">
      <div className="row mx-0 px-0">
        <div className="col-lg-3 col-6 py-2 Bold border-left border-top border-bottom d-flex align-items-center justify-content-center">
          <span> {e.email}</span>
        </div>
        <div className="col-lg-3 col-6 py-2 border-left border-top border-bottom d-flex align-items-center justify-content-center">
          <span>
            {" "}
            <p className="mb-0">{e.phone_number}</p>
          </span>
        </div>
        <div className="col-lg-3 col-6 py-2 border-left border-top border-bottom d-flex align-items-center justify-content-center">
          <span>
            <p className="mb-0">{e.delivery_city}</p>
            <p className="mb-0">{e.delivery_address}</p>
          </span>
        </div>
        <div className="col-lg-3 col-6 py-2 border d-flex align-items-center justify-content-center">
          <button
            type="button"
            class="btn btn-danger mb-0"
            onClick={() => deleteCostumer(e.phone_number)}
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  );
}
