import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CSS/Loading.css";
import { SetSMSLeft, SetpricePerSMS, Setsender } from "../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { SetAllOrders } from "../store/UserReducer";
export default function SingleOrder({ element, b1, b2, b3, func }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const SMSnotyfication = useSelector((e) => e.UserReducer.SMSnotyfication);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const URL = useSelector((e) => e.UserReducer.url);
  const [SMSText, setSMSText] = useState('');
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setSMSText(UserInfo.editable_texts.sms_order_notification)
    }
  }, [UserInfo]);
  //console.log(element);
  const dispatch = useDispatch();
  const [Height, setHeight] = useState("");
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    ////console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  useEffect(() => {
    setHeight(document.getElementById("getHeight").clientHeight);
  }, []);
  const pushNotification = (device_id,subject,text) =>{
    console.log(device_id)
    console.log(subject)
    console.log(text)
    let date = new Date();
    let date_time = date.getTime();
    if (device_id!==undefined && device_id!=='' && subject!==undefined && subject!=='' && text!==undefined && text!==''){
    axios
    .post(
      `${URL}/notifications/order_notification.php`,
      {
        to_who: device_id,
        title: subject,
        body: text,
        sentTime: date_time,
      },
      {
        "Content-Type": "application/json",
      }
    )
    .then((response) => {
      console.log(response)
      
    })
    .catch((err) => {
      console.log(err)
    });
  }
  }
  const [OrderState, setOrderState] = useState("Order");
  const changeState = () => {
    setOrderState("Options");
  };
  const back = () => {
    setOrderState("Order");
  };
  const u = "https://s1.flexmenu.pl/";
  const x = "http://localhost:8000/";
  const link = u;
  const step1 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${link + ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "3",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data === "OK") {
          if (SMSLeft > 0 && SMSnotyfication === true) {
            sendSMS();
         
          }
          func();
          if (element.device_id!==undefined){
            pushNotification(element.device_id,'Cześć','Twoje zamówienie jest w przygotowaniu!')
          }

        }
      })
      .catch((err) => {
        //console.log(err.response);
        setOrderState("Order");
      });
  };

  const step2 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${link + ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "4",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data === "OK") {
          console.log("tak");
          if (element.device_id!==undefined){
          pushNotification(element.device_id,'Cześć','Twoje zamówienie jest w drodze!')
        }
          func();
        }
      })
      .catch((err) => {
        //console.log(err.response);
        setOrderState("Order");
      });
  };
  const step3 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${link + ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "5",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data === "OK") {
          //console.log("tak");
        //  pushNotification(element.device_id,'STEP3','STEP3')
          func();
        }
      })
      .catch((err) => {
        //console.log(err.response);
        setOrderState("Order");
      });
  };
  const sendSMS = () => {
    let control = random();
    axios
      .post(
        `https://api.hostedsms.pl/FullApi/Smses`,
        {
          Phone: ["48" + element.phone_number],
          Message: SMSText,
          Sender: Sender,
          TransactionId: control,
          Priority: "3",
          FlashSms: false,
        },
        {
          headers: {
            "User-Agent": "Fiddler",
            Authorization:
              "Basic ZmVlZHdlYi5jb250YWN0QGdtYWlsLmNvbTpLYXdhc2FraTA4",
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (
          response.data.ErrorMessage === undefined &&
          response.data.MessageIds !== undefined
        ) {
          //console.log(response);
          smsSpend();
        } else {
          console.log(response);
          //smsSpend();
          // setTimeout(function () {}, 1000);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };
  const smsSpend = () => {
    axios
      .put(
        `https://s1.flexmenu.pl/${ResteurantCode}/sms_sent `,
        {
          quantity: 1,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        sms_left();
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const sms_left = () => {
    axios
      .get(`https://s1.flexmenu.pl/${ResteurantCode}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  if (OrderState === "Order") {
    return (
      <blockquote
        className={
          element.is_place
            ? "px-1 my-3 w-100 border-bottom min-h pointer"
            : "px-1 my-3 w-100 border-bottom min-h pointer border border-info"
        }
        id="getHeight"
        onClick={changeState} 
      >
     
        <div className="row mx-0 py-3 px-0">
          <div className="col-12 px-0 fs1">
            <p className="mb-2">
              <span className="mr-3 bold">{element.restaurants_order_id}</span>
              <span
                className={
                  element.payment_type === "1"
                    ? "mr-3 text-success"
                    : "mr-3 text-danger"
                }
              >
                {element.payment_type === "1" ? "Zapłacone" : "Do zapłaty"}
              </span>
              <span className="mr-3 bold">{element.to_pay} zł</span>
              <span className="mr-3 Lite float-right">
                {element.ordered_time}
              </span>
            </p>
          </div>
          <div className="col-12 px-0 mb-2 fs2">
           
              {element.meal_options.map((element) => {
                if (element.additions.length === 0) {
                  return  <p className="mb-1"><span className="bold lower">- {element.name} </span> </p>;
                }
              })}
           
            {element.meal_options.map((element) => {
              if (element.additions.length > 0) {
                let additions = "";
                element.additions.map((elemant) => {
                  additions = additions + elemant.name + ", ";
                });

                return (
                  <p className="mb-1">
                    <span className="bold lower">- {element.name}: </span>
                    <span className="Lite lower">{additions} </span>
                  </p>
                );
              }
            })}
          </div>
          <div className="col-12 px-0 mb-2 Lite fs3">
            <p className="mb-0">{element.comment}</p>
          </div>
          {element.is_place ? (
            <div className="col-12 px-0 Lite fs2">
              <p className="mb-0">
                <span>{element.delivery_city}</span>
              </p>
              <p className="mb-0">
                <span>{element.delivery_address}</span>
              </p>
              <p className="mb-0">
                {element.client_name}, {element.phone_number}, {element.email}
              </p>
            </div>
          ) : (
            <div className="col-12 px-0 Lite upper fs2">
              <p className="mb-0">
                <span>{element.delivery_city}</span>
              </p>
            </div>
          )}
        </div>
      </blockquote>
    );
  } else if (OrderState === "Options") {
    return (
      <blockquote
        className="px-0 border-bottom my-3 min-h d-flex align-items-center"
        style={{}}
      >
        <div className="row mx-0">
          <button
            type="button"
            className={
              b1
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step1}
          >
            W przygotowaniu
          </button>
          <button
            type="button"
            className={
              b2
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step2}
          >
            W doręczniu
          </button>
          <button
            type="button"
            className={
              b3
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step3}
          >
            Zakończ
          </button>
          <button
            type="button"
            className="btn mb-3 w-100 btn-outline-warning upper Bold"
            onClick={back}
          >
            X
          </button>
        </div>
      </blockquote>
    );
  } else if (OrderState === "Loading") {
    return (
      <blockquote
        className="px-0 border-bottom my-3 min-h d-flex align-items-center"
        style={{}}
      >
        <div className="bodyLoad2">
          <div class="loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>{" "}
      </blockquote>
    );
  }
}
