import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
function Nav({ history }) {
  const QR = useSelector((e) => e.UserReducer.qr);
  const redirectOrder = () => {
    history.push("/orders");
  };
  const redirectEndOrder = () => {
    history.push("/endorders");
  };
  const redirectMenu = () => {
    history.push("/menu");
  };
  const redirectMenuDNone = () => {
    history.push("/menu/editmenu");
  };
  const redirectCampaigns = () => {
    history.push("/campaigns");
  };
  const redirectCustomize = () => {
    history.push("/customize");
  };
  const redirectQR = () => {
    history.push("/qr");
  };
  return (
    <>
      <div className="row mx-0">
        <div className="col-12 my-5 text-center">
          <h1 className="upper Bold">witaj w flex-menu</h1>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-md-6 px-md-5  my-3 text-center">
          <button
            type="button "
            className=" w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectOrder}
          >
            Zamówienia
          </button>
        </div>
        <div className="col-md-6 px-md-5 d-lg-block d-none my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectQR}
            disabled={!QR}
          >
            Bezdotykowe Menu
          </button>
          {QR !== null ? (
            !QR ? (
              <p className="my-0 upper Bold ">
                Wprowadz bezdotykowe menu w twojej restauracji!
              </p>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6 px-md-5 d-lg-block d-none my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectMenu}
          >
            Moje menu
          </button>
        </div>
        <div className="col-md-6 px-md-5 d-lg-none d-block my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectMenuDNone}
          >
            Edytuj menu
          </button>
        </div>
        <div className="col-md-6 px-md-5 d-lg-block d-none my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectCustomize}
          >
            Personalizuj
          </button>
        </div>
        <div className="col-md-6 px-md-5  my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-warning"
            onClick={redirectCampaigns}
          >
            Akcje promocyjne
          </button>
        </div>
        <div className="col-md-6 px-md-5  my-3 text-center">
          <button
            type="button"
            className="w-100 py-3 upper Bold btn btn-outline-warning"
            onClick={redirectEndOrder}
          >
            Zakończone Zamówienia
          </button>
        </div>
        <div className="col-md-12 d-block d-lg-none  my-4 text-center">
          <p className="h6 Bold">
            Pełna opcja edycji dostęona jest na komputerach stacjonarnych
          </p>
        </div>
      </div>
    </>
  );
}
export default withRouter(Nav);
