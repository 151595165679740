import React, { useState, useEffect } from "react";

export default function SetDayOpenHours({
  day,
  nr,
  close,
  open,
  funcOpen,
  allOpen,
  funcClose,
  allClose,
}) {
  const [Open, setOpen] = useState(open);
  const [Close, setClose] = useState(close);
  const [Disabled, setDisabled] = useState(null);
  const [Check, setCheck] = useState(null);
  useEffect(() => {
    if (open === "-" && close === "-") {
      setCheck(true);
      setDisabled(true);
    }
  }, []);
  const setChecked = () => {
    if (Check) {
      setCheck(false);
      setDisabled(false);
      if (open !== "-" && close !== "-") {
        setOpen(open);
        setClose(close);
        let x = allOpen;
        x[nr] = open;
        funcOpen(x);

        let y = allClose;
        y[nr] = close;
        funcClose(y);
      } else {
        setOpen("12:00");
        setClose("22:00");
        let x = allOpen;
        x[nr] = "12:00";
        funcOpen(x);

        let y = allClose;
        y[nr] = "22:00";
        funcClose(y);
      }
    } else {
      setCheck(true);
      setOpen("-");
      setClose("-");
      setDisabled(true);
      let x = allOpen;
      x[nr] = "-";
      funcOpen(x);

      let y = allClose;
      y[nr] = "-";
      funcClose(y);
    }
  };
  const changeOpenH = (e) => {
    let x = allOpen;
    x[nr] = e;
    funcOpen(x);
    setOpen(e);
  };
  const changeCloseH = (e) => {
    let x = allClose;
    x[nr] = e;
    funcClose(x);
    setClose(e);
  };
  return (
    <div className="row mx-0 mb-3">
      <div className="col-3 d-flex align-items-center">
        {" "}
        <div class="custom-control custom-checkbox mr-sm-2">
          <input
            type="checkbox"
            checked={Check}
            onChange={setChecked}
            class="custom-control-input"
            id={day}
          />
          <label class="custom-control-label" for={day}>
            {day}
          </label>
        </div>
      </div>
      <div className="col-9 ">
        <div className="row mx-0">
          <div className="col-5 px-0">
            <div class="input-group ">
              <input
                type="text"
                class="form-control text-center"
                value={Open}
                disabled={Disabled}
                id="basic-url"
                aria-describedby="basic-addon3"
                onChange={(e) => {
                  changeOpenH(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-2 px-0 text-center">-</div>
          <div className="col-5 px-0">
            <div class="input-group  ">
              <input
                type="text"
                class="form-control text-center"
                value={Close}
                disabled={Disabled}
                id="basic-url"
                aria-describedby="basic-addon3"
                onChange={(e) => {
                  changeCloseH(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
