import React from "react";

import Header from "./Header";
import "../CSS/Mine.css";
import Nav from "./Nav";
import { useSelector } from "react-redux";
export default function AdminPanel() {
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <Nav />
        </div>{" "}
      </div>
    </>
  );
}
