import React, { useState, useEffect } from "react";
import Header from "./Header";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import EmailCampainMenagment from "./EmailCampainMenagment";
export default function Email() {
  const URL = useSelector((e) => e.UserReducer.url);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [Email, setEmail] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Wyślij Email");
  const [ComunicateText, setComunicateText] = useState("");
  const [Display, setDisplay] = useState("none");
  const [NumberOfEmails, setNumberOfEmails] = useState(null);
  const [Name, setName] = useState("");
  const [Subject, setSubject] = useState("");
  const [AllCampain, setAllCampain] = useState([]);
  useEffect(() => {
    get_all_email_campain();
  }, []);
  const get_all_email_campain = () => {
    axios
      .get(`${URL}/sqlite/get_email_campaigns.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        setAllCampain(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const createEmailCampaing = () => {
    if (Email !== "" && Name !== "" && Subject !== "") {
      axios
        .post(
          `${URL}/sqlite/create_email_campaing.php`,
          {
            name: Name,
            text: Email,
            subject: Subject,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            get_all_email_campain();
            setDisplay("none");
            setComunicateText("Utorzono nową kampanię email");
          } else {
            setDisplay("none");
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setDisplay("none");
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    }
    {
      setDisplay("none");
      setComunicateText("Wpisz treść maila i nazwę kampani");
    }
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div className="row mx-0 px-0">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Stwórz kamapanię mailową</h1>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-md-4 col-12">
              <div class="input-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nazwa kampani "
                  value={Name}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div class="input-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Temat emaila"
                  value={Subject}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              {" "}
              <CKEditor
                editor={ClassicEditor}
                data="Wpisz treść maila"
                onChange={(event, editor) => {
                  let data = editor.getData();
                  setEmail(data);
                }}
              />
              <button
                type="button"
                class="btn btn-success mt-3 "
                disabled={!Active}
                onClick={createEmailCampaing}
              >
                Stwórz kampanię
              </button>
              <p>{ComunicateText}</p>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Twoje kampanie email</h1>
            </div>
          </div>
          <div className="row mx-0 px-0">
            {AllCampain.map((element) => (
              <EmailCampainMenagment e={element} func={get_all_email_campain} />
            ))}
          </div>
        </div>{" "}
      </div>
    </>
  );
}
