import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import axios from "axios";

const Delivery = () => {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const URL = useSelector((e) => e.UserReducer.url);
  
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj strefę");
  const [ComunicateText, setComunicateText] = useState("");
  const [Delivery, setDelivery] = useState(null);
  useEffect(() => {
    getDeliveryPlaces();
  }, []);
  useEffect(() => {
    getDeliveryPlaces();
  }, [Active]);
  const getDeliveryPlaces = async () => {
    await axios
      .get(`${URL}/get_delivery_places.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);
        setDelivery(response.data.places);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [City, setCity] = useState("");
  const [FreeAbove, setFreeAbove] = useState("");
  const [Price, setPrice] = useState("");
  const ValidCity = (Value) => {
    setCity(Value);
  };
  const ValidPrice = (Value) => {
    setPrice(Value);
    // //console.log(Value);
  };
  const ValidFreeAbove = (Value) => {
    setFreeAbove(Value);
    ////console.log(Value);
  };
  const removeCity = (id) => {
    ////console.log(id);
    axios
      .delete(
        `https://s1.flexmenu.pl/${ResteurantCode}/delivery_place/${id}`
      )
      .then((response) => {
        ////console.log(response);
        if (response.status === 204 && response.statusText === "No Content") {
          //  //console.log(response);
          getDeliveryPlaces();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const addDelivery = () => {
    if (
      City !== "" &&
      FreeAbove !== "" &&
      Price !== "" &&
      ZL !== "" &&
      ZL2 !== ""
    ) {
      setActive(false);
      setText("Czekaj");
      let json = {
        name: City,
        delivery_price: Price,
        free_above: FreeAbove,
        is_place: true,
      };
      ////console.log(json);
      axios
        .post(
          `https://s1.flexmenu.pl/${ResteurantCode}/delivery_places`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          // //console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //  //console.log(response);

            setActive(true);
            setText("Dodaj strefę");
            setComunicateText("Dodano! Możesz teraz dodać kolejną strefę");
            getDeliveryPlaces();
            setCity("");
            setGR("");
            setGR2("");
            setZL("");
            setZL2("");
            setFreeAbove("");
            setPrice("");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      setComunicateText("Uzupełnij wszytkie pola");
    }
  };
  const [GR, setGR] = useState("");
  const [ZL, setZL] = useState("");
  const ValidGR = (value) => {
    if (value.length < 3) {
      setGR(value);
      sendPrice1(2, value);
    }
  };
  const sendPrice1 = (x, value) => {
    if (x === 1) {
      let p = FreeAbove;
      p = value + "." + GR;
      ValidFreeAbove(p);
    } else if (x === 2) {
      let p = FreeAbove;
      p = ZL + "." + value;
      ValidFreeAbove(p);
    }
  };

  const [GR2, setGR2] = useState("");
  const [ZL2, setZL2] = useState("");
  const ValidGR2 = (value) => {
    if (value.length < 3) {
      setGR2(value);
      sendPrice2(2, value);
    }
  };
  const sendPrice2 = (x, value) => {
    if (x === 1) {
      let p = FreeAbove;
      p = value + "." + GR2;
      ValidPrice(p);
    } else if (x === 2) {
      let p = FreeAbove;
      p = ZL2 + "." + value;
      ValidPrice(p);
    }
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Strefy Dostaw</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-5 offset-1">
                <label>Miejscowość</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={City}
                    id="basic-url"
                    onChange={(e) => {
                      ValidCity(e.target.value);
                    }}
                    aria-describedby="basic-addon3"
                  />
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-5 px-0">
                    <label>Darmowa dostawa od*</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        value={ZL}
                        onChange={(e) => {
                          setZL(e.target.value);
                          sendPrice1(1, e.target.value);
                        }}
                        class="form-control"
                        placeholder="zł"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />{" "}
                      <input
                        type="text"
                        value={GR}
                        onChange={(e) => ValidGR(e.target.value)}
                        class="form-control"
                        placeholder="gr"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-5 offset-1 px-0">
                    <label>Cena transportu*</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        value={ZL2}
                        class="form-control"
                        placeholder="zł"
                        onChange={(e) => {
                          setZL2(e.target.value);
                          sendPrice2(1, e.target.value);
                        }}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />{" "}
                      <input
                        type="text"
                        value={GR2}
                        class="form-control"
                        placeholder="gr"
                        onChange={(e) => ValidGR2(e.target.value)}
                        id="basic-url"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-success mt-3"
                  onClick={() => addDelivery()}
                  disabled={!Active}
                >
                  {Text}
                </button>
                <p>{ComunicateText}</p>
              </div>
              <div className="col-6 ">
                <div className="row">
                  <div className="col-2 mb-3"></div>
                  <div className="col-6 Bold mb-3">Strefa</div>
                  <div className="col-2 mb-3">Koszt</div>
                  <div className="col-2 mb-3">Darmowa </div>
                  {Delivery !== null
                    ? Object.keys(Delivery).map((element) => {
                        if (Delivery[element].is_place) {
                          return (
                            <>
                              <div className="col-2 my-2 pl-4 pr-0 text-center ">
                                <button
                                  type="button"
                                  class="btn btn-outline-danger border-0 px-3 py-0"
                                  onClick={() =>
                                    removeCity(Delivery[element].id)
                                  }
                                >
                                  X
                                </button>
                              </div>
                              <div className="col-6 my-2 Bold d-flex align-items-center">
                                {Delivery[element].name}
                              </div>
                              <div className="col-2 my-2">
                                {Delivery[element].delivery_price}
                                {" zł"}
                              </div>
                              <div className="col-2 my-2">
                                {Delivery[element].free_above}
                                {" zł"}
                              </div>
                            </>
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
};

export default Delivery;
