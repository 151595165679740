import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import "../CSS/Mine.css";
import SingleOrder from "./SingleOrder";
import { useDispatch, useSelector } from "react-redux";
import { SetOrders } from "../store/UserReducer";
export default function AdminPanel() {
  const [Orderss, setOrderss] = useState("");
  const [Orders2, setOrders] = useState([]);
  const Orders = useSelector((e) => e.UserReducer.orders);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const URL = useSelector((e) => e.UserReducer.url);
  const dispatch = useDispatch();
  useEffect(() => {
    set();
    console.log("TEST")
  }, []);
  const set = () => {
    let d = new Date();
    let n = d.getTime();
    setOrderss(n);
    axios
      .get(`${URL}/set_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200 && response.data === "OK") {
          add();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const add = () => {
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
      console.log(response.data);
        if (response.status === 200) {
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          setOrders(orders_array);

          // orders_array.map((element) => {
          console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });

          //  //console.log("kurwaaa");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeState = () => {};
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div
            className="row py-md-2 py-1 w-100 mx-0"
            style={{
              height: window.innerHeight - 120,
            }}
          >
            <div
              className="col-12 px-1 h-100"
              style={{
                height: window.innerHeight - 120,
              }}
            >
              <div
                className="card"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="card-header upper text-center Bold bg-warning">
                  Zakończone
                </div>
                <div className="card-body b-card py-0 px-1">
                  {Orders2.map((element) => {
                    if (element.progress === "5") {
                      return (
                        <SingleOrder
                          element={element}
                          changestate={changeState}
                          b1={0}
                          b2={0}
                          b3={0}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
