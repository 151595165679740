import React from "react";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
export function Campaigns({ history }) {
  const redirectCupons = () => {
    history.push("/campaigns/cupons");
  };
  const redirectEmail = () => {
    history.push("/campaigns/email");
  };
  const redirectSMS = () => {
    history.push("/campaigns/sms");
  };
  const redirectCustomerManagement = () => {
    history.push("/campaigns/customermanagement");
  };
  const redirectPushNotification = () => {
    history.push("/campaigns/pushnotification");
  };
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row mx-0 px-0">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Moje promocje</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-md-6 px-md-5  my-3 text-center my-3 text-center">
                <button
                  type="button "
                  className="w-100 py-3 upper Bold btn btn-warning"
                  onClick={redirectCupons}
                >
                  Kupony rabatowe
                </button>
              </div>
              <div className="col-md-6 px-md-5  my-3 text-center my-3 text-center">
                <button
                  type="button"
                  className="w-100 py-3 upper Bold btn btn-warning"
                  onClick={redirectEmail}
                >
                  Kampania emailowa
                </button>
              </div>
              <div className="col-md-6 px-md-5  my-3 text-center my-3 text-center">
                <button
                  type="button"
                  className="w-100 py-3 upper Bold btn btn-warning"
                  onClick={redirectSMS}
                >
                  Kampania SMS
                </button>
              </div>
              <div className="col-md-6 px-md-5  my-3 text-center my-3 text-center">
                <button
                  type="button"
                  className="w-100 py-3 upper Bold btn btn-warning"
                  onClick={redirectPushNotification}
                >
                  Powiadomienia Android/iOS
                </button>
              </div>
              <div className="col-md-6 px-md-5  my-3 text-center my-3 text-center">
                <button
                  type="button"
                  className="w-100 py-3 upper Bold btn btn-warning"
                  onClick={redirectCustomerManagement}
                >
                  Zadządzaj danymi kientów
                </button>
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
}
export default withRouter(Campaigns);
