import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SingleOrder from "./SingleOrder";
import { SetOrders, SetLengthOrder } from "../store/UserReducer";
import { Howl } from "howler";

import "../CSS/Orders.css";
export default function Orders() {
  const Orders = useSelector((e) => e.UserReducer.orders);
  const URL = useSelector((e) => e.UserReducer.url);
  //const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const [Orderss, setOrderss] = useState();
  const [Orders2, setOrders] = useState([]);
  const [interval_counter, setIntervalCounter] = useState(0);
  const soundPlay = (src) => {
    const sound = new Howl({
      src,
      html5: true,
    });
    sound.play();
  };
  const changeState = () => {
    let d = new Date();
    let n = d.getTime();
    setOrderss(n);
    return true;
  };
  let temp_interval_counter = 0;
  const dispatch = useDispatch();
  useEffect(() => {
    clearInterval();
    add();
    //console.log(Bool);
    let int = setInterval(() => {
      changeState();
      add();
      temp_interval_counter++;
      setIntervalCounter(temp_interval_counter);
      console.log(`Interval counter: ${temp_interval_counter}`);
      changeState();
      SetLengthOrder(1, dispatch);
    }, 6000);
    return () => clearInterval(int);
  }, []);

  let pre_update_orders_count = 0;
  let not_first_run = false;
  const add = () => {
    //  //console.log(Orders2);
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (response.status === 200) {
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          // orders_array.map((element) => {
          console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });
          //console.log(pre_update_orders_count);
          changeState();
          setOrders(orders_array);
          if (
            pre_update_orders_count !== Object.keys(response.data).length &&
            not_first_run
          ) {
            if (changeState()) {
              changeState();
              soundPlay(`${URL}/mp3/ring.mp3`);
              changeState();
            }
            //console.log(pre_update_orders_count);
          }
          not_first_run = true;
          pre_update_orders_count = orders_array.length;
          //  //console.log("kurwaaa");
        }
        changeState();
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        changeState();
        change();
        setTimeout(() => {
          add();
        }, 500);
      });
  };

  const change = () => {
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (response.status === 200) {
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          setOrders(orders_array);

          // orders_array.map((element) => {
          //console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });

          //  //console.log("kurwaaa");
        }
        changeState();
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        changeState();
      });
  };
  // useEffect(() => {
  // if (ResteurantCode !== null) {
  // axios
  // .get(
  // `https://s1.flexmenu.pl/${ResteurantCode}/orders`,
  // {
  //  "Content-Type": "application/json",
  //}
  //)
  //.then((response) => {
  //  if (response.status === 200) {
  //   Object.keys(response.data).map((element) => {
  //   SetOrderss(response.data[element], dispatch);
  // setOrderss(response.data[element]);
  //  //console.log(element);
  // });
  // }
  //})
  //.catch((err) => {
  //  //console.log(err);
  //});
  // }
  //}, [ResteurantCode]);

  return (
    <>
      <Header />
      <div
        className="container-fluid outer-wrapper px-0"
        style={{
          background: "white",
          minHeight: window.innerHeight - 120,
          paddingTop: "70px",
        }}
      >
        <div className="wrapper">
          <div
            className="row py-md-2 py-1 w-100 mx-0"
            style={{
              height: window.innerHeight - 120,
            }}
          >
            <div
              className="col-4 px-1 h-100"
              style={{
                height: window.innerHeight - 120,
              }}
            >
              <div
                className="card"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="card-header upper text-center Bold bg-warning">
                  W kolejce{" "}
                  <span className="invisible-text">{interval_counter}</span>
                </div>
                <div
                  className="card-body b-card py-0 px-0"
                  style={{
                    height: "100%",
                  }}
                >
                  {Orders2.map((element) => {
                    if (element.progress === "2") {
                      return (
                        <SingleOrder
                          element={element}
                          b1={1}
                          b2={1}
                          b3={1}
                          func={change}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <div
              className="col-4 px-1"
              style={{
                height: window.innerHeight - 120,
              }}
            >
              <div
                className="card"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="card-header upper text-center Bold bg-warning">
                  w przygotowaniu{" "}
                  <span className="invisible-text">{interval_counter}</span>
                </div>
                <div className="card-body b-card py-0 px-0">
                  {Orders2.map((element) => {
                    if (element.progress === "3") {
                      return (
                        <SingleOrder
                          element={element}
                          b1={0}
                          b2={1}
                          b3={1}
                          func={change}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <div
              className="col-4 px-1"
              style={{
                height: window.innerHeight - 120,
              }}
            >
              <div
                className="card"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="card-header upper text-center Bold bg-warning">
                  W doręczeniu{" "}
                  <span className="invisible-text">{interval_counter}</span>
                </div>
                <div className="card-body b-card py-0 px-0">
                  {Orders2.map((element) => {
                    if (element.progress === "4") {
                      return (
                        <SingleOrder
                          element={element}
                          b1={0}
                          b2={0}
                          b3={1}
                          func={change}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
