import React, { useState } from "react";
import { useAccordionToggle } from "react-bootstrap";
export default function CustomToggle({ children, eventKey }) {
  const [Text, setText] = useState("Pokaż dania");
  const change = () => {
    if (Text === "Pokaż dania") {
      setText("Ukryj dania");
    } else if (Text === "Ukryj dania") {
      setText("Pokaż dania");
    }
  };
  const decoratedOnClick = useAccordionToggle(eventKey, () => change());
  return (
    <button
      type="button"
      className=" mx-1 Lite btn btn-outline-dark px-1 py-1"
      onClick={decoratedOnClick}
    >
      {Text}
    </button>
  );
}
