import React, { useState, useEffect } from "react";

export default function Cupon({ func, element }) {
  const [ArrayMine, setArrayMine] = useState([]);
  useEffect(() => {
    let arraymine = [];
    element.coupons.map((e) => {
      arraymine.push(e.id);
    });
    setArrayMine(arraymine);
    //   console.log(arraymine);
  }, []);
  // console.log(element);

  const [Hide, setHide] = useState("none");
  const hide = () => {
    if (Hide === "none") {
      setHide("block");
    } else {
      setHide("none");
    }
  };
  return (
    <>
      <div className="row mx-0 px-0 my-2">
        <div className="col-2 my-1 mx-0 px-0 text-left d-flex align-items-center">
          <button
            type="button"
            class="btn btn-danger px-1 py-0"
            onClick={() => func(ArrayMine)}
          >
            Usuń
          </button>
        </div>

        <div className="col-4 my-1 upper Bold d-flex align-items-center">
          {element.code}
        </div>
        <div className="col-2 my-1 d-flex align-items-center">
          {element.percent}
          {"%"}
        </div>
        <div className="col-2 my-1 d-flex align-items-center">
          <p className="mb-0">
            {" "}
            <button
              class="btn btn-primary px-2 py-0"
              type="button"
              onClick={() => hide()}
            >
              Rozwiń
            </button>
          </p>
        </div>
      </div>
      <div class="row mx-0 px-0" style={{ display: Hide }}>
        <div class="col mx-0 px-0">
          <div class="card card-body py-2 px-1">
            {element.coupons.map((e) => (
              <p className="mb-1">
                <span>{e.meal_base}</span>
                {e.meal_option !== null ? (
                  <span> {"- " + e.meal_option}</span>
                ) : (
                  ""
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
