import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { useSelector } from "react-redux";
export default function EmailCampainMenagment({ e, func }) {
  const URL = useSelector((e) => e.UserReducer.url);
  const [Display, setDisplay] = useState("none");
  const send = (id) => {
    axios
      .get(`${URL}/sqlite/send_email_campaign.php/?id=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
       // console.log(response);
        setDisplay("flex");
        setTimeout(function () {
          func();
          setDisplay("none");
        }, 1500);
      })

      .catch((err) => {
      //  console.log(err.response);
      });
  };
  const deleteCampain = (id) => {
    axios
      .get(`${URL}/sqlite/delete_email_campaign.php/?id=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
       // console.log(response);
        func();
      })

      .catch((err) => {
      //  console.log(err.response);
      });
  };
  return (
    <div className="col-12 my-3">
      <div className="row mx-0 px-0">
        <div className="col-md-2 col-12 py-2 Bold border-left border-top border-bottom d-flex justify-content-center align-items-center">
          {e.name}
        </div>

        <div className="col-md-7 col-12 py-2 border-left border-top border-bottom ">
          <p className="Bold upper mb-0">{e.subject}</p>
          {ReactHtmlParser(e.text)}
        </div>
        <div className="col-md-3 col-12 py-2 border text-center justify-content-center align-items-center position-relative">
          {e.status === "0" ? (
            <>
              <button
                type="button"
                class="btn btn-success mb-0 mr-3"
                onClick={() => send(e.id)}
              >
                Wyślij
              </button>
              <button
                type="button"
                class="btn btn-danger mb-0"
                onClick={() => deleteCampain(e.id)}
              >
                Usuń
              </button>
            </>
          ) : (
            <>
              <div class="alert alert-success" role="alert">
                Kampania zrealizowana
              </div>

              <button
                type="button"
                class="btn btn-danger mb-0"
                onClick={() => deleteCampain(e.id)}
              >
                Usuń kampanię
              </button>
            </>
          )}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "green",
              display: Display,
            }}
            className=" justify-content-center align-items-center Bold text-white"
          >
            Wysłano
          </div>
        </div>
      </div>
    </div>
  );
}
