import React, { useEffect, useState } from "react";
import Header from "./Header";
import FileBase64 from "react-file-base64";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SetDayOpenHours from "./SetDayOpenHours";
export default function CompanyInfo() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");

  const [CompanyName, setCompanyName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phoneday, setPhoneday] = useState("");
  const [FBLink, setFBLink] = useState("");
  const [InstLink, setInstLink] = useState("");
  const [City, setCity] = useState("");
  const [Street, setStreet] = useState("");
  const [NIP, setNIP] = useState("");
  const [FileText, setFileText] = useState(null);
  const [FileName, setFileName] = useState(null);
  const [G_A_ID, setG_A_ID] = useState('');
  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name.replace(/\s+/g, ""));
  };
  const [ClosingHour, setClosingHour] = useState(null);
  const [OpeningHour, setOpeningHour] = useState(null);
const [Marketing_email, setMarketing_email] = useState('');
const [Marketing_email_pass, setMarketing_email_pass] = useState('');
const [Marketin_email_domain, setMarketin_email_domain] = useState('');
const [Marketing_email_signature, setMarketing_email_signature] = useState('');
const [FCM_Server_key, setFCM_Server_key] = useState('')
const [NewPass, setNewPass] = useState('');
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setCompanyName(UserInfo.editable_texts.restaurant_name);
      setEmail(UserInfo.editable_texts.restaurant_email);
      setPhoneday(UserInfo.editable_texts.restaurant_phone);
      setFBLink(UserInfo.editable_texts.facebook_link);
      setInstLink(UserInfo.editable_texts.instagram_link);
      setCity(UserInfo.editable_texts.restaurant_city);
      setStreet(UserInfo.editable_texts.restaurant_street);
      setNIP(UserInfo.editable_texts.restaurant_nip);
      setG_A_ID(UserInfo.editable_texts.google_analistic_ID);
      setMarketing_email(UserInfo.editable_texts.marketing_email);
      setMarketing_email_pass(UserInfo.editable_texts.marketing_email_pass);
      setMarketin_email_domain(UserInfo.editable_texts.marketin_email_domain);
      setMarketing_email_signature(UserInfo.editable_texts.marketing_email_signature);
      setFCM_Server_key(UserInfo.editable_texts.FCM_Server_key);
    }
    if (UserInfo.closing_hour !== undefined) {
      setClosingHour(UserInfo.closing_hour);
    }
    if (UserInfo.opening_hour !== undefined) {
      setOpeningHour(UserInfo.opening_hour);
    }
  }, [UserInfo]);
  const setWebsiteData = () => {
    //console.log(ClosingHour);
    //console.log(OpeningHour);
    let newPass=''
    if(NewPass!==''){
      newPass=NewPass;
    }else{
      newPass=Marketing_email_pass;
    }
    let json = {};
    if (FileName !== null) {
      json = {
        editable_texts: {
          restaurant_name: CompanyName,
          restaurant_email: Email,
          restaurant_phone: Phoneday,
          facebook_link: FBLink,
          instagram_link: InstLink,
          restaurant_city: City,
          restaurant_street: Street,
          restaurant_nip: NIP,
          closing_hour: ClosingHour,
          opening_hour: OpeningHour,
          google_analistic_ID:G_A_ID,
          marketing_email:Marketing_email,
          marketing_email_pass:newPass,
          marketin_email_domain:Marketin_email_domain,
          marketing_email_signature:Marketing_email_signature,
          FCM_Server_key:FCM_Server_key
        },
        img: {
          logo: {
            photo_url: "/img/restaurant/" + FileName,
            encoded_photo: FileText,
          },
        },
        closing_hour: ClosingHour,
        opening_hour: OpeningHour,
      };
    } else if (FileName === null) {
      json = {
        editable_texts: {
          restaurant_name: CompanyName,
          restaurant_email: Email,
          restaurant_phone: Phoneday,
          facebook_link: FBLink,
          instagram_link: InstLink,
          restaurant_city: City,
          restaurant_street: Street,
          restaurant_nip: NIP,
          google_analistic_ID:G_A_ID,
          marketing_email:Marketing_email,
          marketing_email_pass:newPass,
          marketin_email_domain:Marketin_email_domain,
          marketing_email_signature:Marketing_email_signature,
          FCM_Server_key:FCM_Server_key
        },
        closing_hour: ClosingHour,
        opening_hour: OpeningHour,
      };
    }
    let json2 = {
      closing_hour: ClosingHour,
      opening_hour: OpeningHour,
    };
    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          axios
            .post(`${URL}/set_open_close.php`, json2, {
              "Content-Type": "application/json",
            })
            .then((response) => {
              //console.log(response);

              if (response.status === 200) {
                setActive(true);
                setText("Zapisz zmiany");
                setComunicateText("Zapisano zmiany");
              }
            })
            .catch((err) => {
              //console.log(err.response);
              //console.log(err.request);
              //console.log("Error", err.message);
              setComunicateText("Cos poszło nie tak");
            });
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{

          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Dane firmy</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Nazwa firmy</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={CompanyName}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Adres e-mail</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Email}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Telefon*</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Phoneday}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setPhoneday(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">adres URL Facebook</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={FBLink}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setFBLink(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">adres URL Instagram</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={InstLink}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setInstLink(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Google Analistic ID</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={G_A_ID}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setG_A_ID(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">FCM server key</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={FCM_Server_key}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setFCM_Server_key(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Miejscowość</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={City}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Ulica i numer</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Street}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setStreet(e.target.value);
                    }}
                  />
                </div>

                <label className="mb-0">NIP</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={NIP}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setNIP(e.target.value);
                    }}
                  />
                </div>
                <label className="m2-0">Logo</label>
                <div class="input-group mb-4">
                  <FileBase64 multiple={false} onDone={File.bind()} />
                </div>
              </div>
              <div className="col-4 px-2 mx-0">
                <label className="m2-0">Godziny otwarcia</label>
                {ClosingHour !== null && OpeningHour !== null ? (
                  <>
                    <SetDayOpenHours
                      day="PN"
                      nr={1}
                      open={OpeningHour[1]}
                      close={ClosingHour[1]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="WT"
                      nr={2}
                      open={OpeningHour[2]}
                      close={ClosingHour[2]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="ŚR"
                      nr={3}
                      open={OpeningHour[3]}
                      close={ClosingHour[3]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="CZW"
                      nr={4}
                      open={OpeningHour[4]}
                      close={ClosingHour[4]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="PT"
                      nr={5}
                      open={OpeningHour[5]}
                      close={ClosingHour[5]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="SB"
                      nr={6}
                      open={OpeningHour[6]}
                      close={ClosingHour[6]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                    <SetDayOpenHours
                      day="ND"
                      nr={0}
                      open={OpeningHour[0]}
                      close={ClosingHour[0]}
                      allOpen={OpeningHour}
                      funcOpen={setOpeningHour}
                      allClose={ClosingHour}
                      funcClose={setClosingHour}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-2 text-center">
                <h1 className="upper Bold">Marketingowe konto email </h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
            
            <div className="col-4 px-2 mx-0">
                <label className="mb-0">Podpis nadawcy</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Marketing_email_signature}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setMarketing_email_signature(e.target.value);
                    }}
                  />
                </div>
              </div>
            <div className="col-4 px-2 mx-0">
                <label className="mb-0">Email</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Marketing_email}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setMarketing_email(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Hasło</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="***** ***"
                    value={NewPass}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Host email</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={Marketin_email_domain}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setMarketin_email_domain(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-success mx-2 mt-3"
              onClick={() => setWebsiteData()}
              disabled={!Active}
            >
              {Text}
            </button>
            <p className=" mx-2">{ComunicateText}</p>
          </>
        </div>{" "}
      </div>
    </>
  );
}
