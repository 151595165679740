import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
const Texts = () => {
  const URL = useSelector((e) => e.UserReducer.url);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [HeaderText1, setHeaderText1] = useState("");
  const [HeaderText2, setHeaderText2] = useState("");
  const [ShowMoreOptionsButtonText, setShowMoreOptionsButtonText] = useState(
    ""
  );

  const [HeaderConfirmSectionText, setHeaderConfirmSectionText] = useState("");
  const [AlertCloseText, setAlertCloseText] = useState("");

  const [MyOrderHeaderText, setMyOrderHeaderText] = useState("");
  const [CodeInpotPleaceholder, setCodeInpotPleaceholder] = useState("");
  const [ClientTextPleaceholder, setClientTextPleaceholder] = useState("");
  const [DeliveryAdresHeaderText, setDeliveryAdresHeaderText] = useState("");
  const [ContactHeaderText, setContactHeaderText] = useState("");
  const [SubmitButtonText, setSubmitButtonText] = useState("");

  const [OpenHoursInformationText, setOpenHoursInformationText] = useState("");
  const [SocialMediaHeaderText, setSocialMediaHeaderText] = useState("");
  const [AcceptOrderHeaderText, setAcceptOrderHeaderText] = useState("");
  const [AcceptOrderComunicatText, setAcceptOrderComunicatText] = useState("");
  const [DenyOrderHeaderText, setDenyOrderHeaderText] = useState("");
  const [DenyOrdedComunicatText, setDenyOrdedComunicatText] = useState("");
  const [InformationTitle, setInformationTitle] = useState('');
  const [InformationText, setInformationText] = useState('');
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
const [SMSText, setSMSText] = useState('');
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setHeaderText1(UserInfo.editable_texts.header_text_1);
      setHeaderText2(UserInfo.editable_texts.header_text_2);
      setShowMoreOptionsButtonText(UserInfo.editable_texts.meal_sizes_button);
      //setCartButtonTExt()
      //setCartSectionText();
      setHeaderConfirmSectionText(UserInfo.editable_texts.order_summary);
      setAlertCloseText(UserInfo.editable_texts.restaurant_auto_closed_alert);
      setMyOrderHeaderText(UserInfo.editable_texts.my_order_label);
      setDeliveryAdresHeaderText(
        UserInfo.editable_texts.delivery_address_header
      );
      setCodeInpotPleaceholder(UserInfo.editable_texts.promo_code_label);
      setClientTextPleaceholder(UserInfo.editable_texts.comment_to_order);
      setContactHeaderText(UserInfo.editable_texts.delivery_client_contact);
      setSubmitButtonText(UserInfo.editable_texts.confirm_order_button);
      setOpenHoursInformationText(
        UserInfo.editable_texts.restaurant_open_hours
      );
      setSocialMediaHeaderText(UserInfo.editable_texts.visit_socials);
      setAcceptOrderHeaderText(UserInfo.editable_texts.order_accepted_title);
      setAcceptOrderComunicatText(
        UserInfo.editable_texts.online_order_accepted_text
      );
      setDenyOrderHeaderText(UserInfo.editable_texts.order_error_title);
      setDenyOrdedComunicatText(
        UserInfo.editable_texts.online_order_error_text
      );
      setSMSText(UserInfo.editable_texts.sms_order_notification)
      setInformationTitle(UserInfo.editable_texts.information_title)
      setInformationText(UserInfo.editable_texts.information_text)
    }
  }, [UserInfo]);

  const setWebsiteData = () => {
    let json = {
      editable_texts: {
        header_text_1: HeaderText1,
        header_text_2: HeaderText2,
        meal_sizes_button: ShowMoreOptionsButtonText,
        sms_order_notification:SMSText,
        order_summary: HeaderConfirmSectionText,
        my_order_label: MyOrderHeaderText,
        promo_code_label: CodeInpotPleaceholder,
        comment_to_order: ClientTextPleaceholder,
        delivery_address_header: DeliveryAdresHeaderText,
        delivery_client_contact: ContactHeaderText,
        confirm_order_button: SubmitButtonText,
        restaurant_open_hours: OpenHoursInformationText,
        visit_socials: SocialMediaHeaderText,
        order_accepted_title: AcceptOrderHeaderText,
        online_order_accepted_text: AcceptOrderComunicatText,
        order_error_title: DenyOrderHeaderText,
        online_order_error_text: DenyOrdedComunicatText,
        restaurant_auto_closed_alert: AlertCloseText,
        information_title: InformationTitle,
        information_text: InformationText
      },
    };
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Teksty</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Nagłówek pierwszy</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={HeaderText1}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setHeaderText1(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Nagłówek drugi</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={HeaderText2}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setHeaderText2(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Przycisk rozwijający danie</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={ShowMoreOptionsButtonText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setShowMoreOptionsButtonText(e.target.value);
                    }}
                  />
                </div>

                <label className="mb-0">Nagłówke akceptacji zamówienia</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={HeaderConfirmSectionText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setHeaderConfirmSectionText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Alert gdy restauracja jest zamknięta
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={AlertCloseText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAlertCloseText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Treść powiadomienia SMS 
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={SMSText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setSMSText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Lokalizacja FoodTruck tytuł </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={InformationTitle}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setInformationTitle(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Nagłówke podsumowania zamówienia</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={MyOrderHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setMyOrderHeaderText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Pole na kod promocyjny</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={CodeInpotPleaceholder}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setCodeInpotPleaceholder(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Pole na uwagi do zamówień</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={ClientTextPleaceholder}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setClientTextPleaceholder(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Nagłówek sekcji z adresem dostawy
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={DeliveryAdresHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setDeliveryAdresHeaderText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Nagłówek sekcji z danymi kontaktowymi
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={ContactHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setContactHeaderText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Przycisk potwierdzający zamówienie
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={SubmitButtonText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setSubmitButtonText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Lokalizacja FoodTruck treść  </label>
                <div class="input-group mb-4">
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={InformationText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setInformationText(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Nagłówek z social media (stopka)</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={SocialMediaHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setSocialMediaHeaderText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Nagłówke informujący o akceptacji zamówienia
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={AcceptOrderHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAcceptOrderHeaderText(e.target.value);
                    }}
                  />
                </div>

                <label className="mb-0">Opis przy akceptacji zamówienia</label>
                <div class="input-group mb-4">
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={AcceptOrderComunicatText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAcceptOrderComunicatText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">
                  Nagłówke informujący o odmowie zamówienia
                </label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={DenyOrderHeaderText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setDenyOrderHeaderText(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Opis przy odmowie zamówienia</label>
                <div class="input-group mb-4">
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={DenyOrdedComunicatText}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setDenyOrdedComunicatText(e.target.value);
                    }}
                  />
                </div>



           
              </div>
              <div className="col-8 px-2 mx-0"></div>
            </div>
            <button
              type="button"
              class="btn btn-success mx-2 mt-3"
              onClick={() => setWebsiteData()}
              disabled={!Active}
            >
              {Text}
            </button>
            <p className=" mx-2">{ComunicateText}</p>
          </>
        </div>{" "}
      </div>
    </>
  );
};

export default Texts;
