import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "./Header";
import Cupon from "./Cupon";
export default function Cupons() {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);

  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [EditableMenu, setEditableMenu] = useState(null);
  useEffect(() => {
    if (Menu !== null) {
      setEditableMenu(Menu.meal_categories);
    }
  }, [Menu]);
  useEffect(() => {
    getCupons();
  }, []);
  const [Cupons, setCupons] = useState(null);
  const getCupons = () => {
    axios
      .get(`https://s1.flexmenu.pl/${ResteurantCode}/coupons`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          //console.log(response.data);
          setCupons(null);
          setCupons(response.data);
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const removeCupons = (element) => {
    //console.log(element);

    axios
      .post(
        `https://s1.flexmenu.pl/${ResteurantCode}/coupon/1`, // '1' jest wartościa nie uzywaną (musi być bo dawid nie umie programować)
        {
          coupon_ids: element,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          getCupons();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [Code, setCode] = useState("");
  const [Percent, setPercent] = useState("");
  const ValidCode = (val) => {
    setCode(val);
  };
  const ValidPercent = (val) => {
    setPercent(val);
  };
  const [CuponList, setCuponList] = useState({});
  const CuponsMealsList = (id) => {
    let arr = CuponList;
    if (arr[id] === undefined) {
      arr[id] = id;
    } else {
      delete arr[id];
    }
    // //console.log(arr);
    //  //console.log(id);
    setCuponList(arr);
  };
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj kupon");
  const [ComunicateText, setComunicateText] = useState("");
  const addCoupon = () => {
    if (Code !== "" && Percent !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        code: Code,
        percent: Percent,
        meal: CuponList,
      };

      //  //console.log(json);
      axios
        .post(`https://s1.flexmenu.pl/${ResteurantCode}/coupons`, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //console.log(response);
            clear();
            setActive(true);
            setText("Dodaj kupon");
            setComunicateText("Dodano! Możesz teraz dodać kolejny kupon");
            getCupons();
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
          setText("Dodaj kupon");
          setComunicateText(
            "Uzupełnij wszytkie pola i przypisz kupon do potrawy zaznaczjac ją. Pamiętaj, kody kuponów nie mogą sie powtarzać"
          );
        });
    } else {
      setActive(true);
      setText("Dodaj kupon");
      setComunicateText(
        "Uzupełnij wszytkie pola i przypisz kupon do potrawy zaznaczjac ją."
      );
    }
  };
  const clear = () => {
    setCode("");
    setPercent("");
  };
  if (EditableMenu !== null) {
    return (
      <>
        <Header />
        <div
          className="container-fluid mine-background "
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            minHeight: window.innerHeight,
            paddingTop: "70px",
          }}
        >
          <div
            className="container display  px-0 position-relative "
            style={{ background: "white", minHeight: window.innerHeight }}
          >
            <>
              <div className="row">
                <div className="col-12 my-5 text-center">
                  <h1 className="upper Bold">Kupony</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 offset-lg-1 px-lg-0 px-5">
                  <label>Kod kuponu</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control upper"
                      placeholder=""
                      value={Code}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        ValidCode(e.target.value.toUpperCase());
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <label>% zniżki</label>
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          value={Percent}
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          onChange={(e) => {
                            ValidPercent(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-none d-lg-block">
                    <button
                      type="button"
                      class="btn btn-success mt-3 "
                      onClick={() => addCoupon()}
                      disabled={!Active}
                    >
                      {Text}
                    </button>
                    <p className="mb-5">{ComunicateText}</p>{" "}
                  </div>
                  <div className="d-none d-lg-block">
                    {Cupons !== null
                      ? Object.keys(Cupons).map((e) => {
                          return (
                            <Cupon func={removeCupons} element={Cupons[e]} />
                          );
                        })
                      : ""}{" "}
                  </div>
                </div>
                <div className="col-lg-7 px-lg-5 px-5">
                  {Object.keys(EditableMenu).map((element) => (
                    <>
                      <div className="col-12 mt-3 mx-0 px-0 text-center h4">
                        <span className="Bold upper">
                          {EditableMenu[element].name}
                        </span>
                      </div>
                      {Object.keys(EditableMenu[element].meal_bases).map(
                        (x) => {
                          if (
                            Object.keys(
                              EditableMenu[element].meal_bases[x].meal_options
                            ).length === 1
                          ) {
                            return (
                              <div class="custom-control custom-checkbox mr-sm-2 my-2">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id={
                                    EditableMenu[element].meal_bases[x].name +
                                    EditableMenu[element].meal_bases[x].id +
                                    EditableMenu[element].meal_bases[x].name
                                  }
                                  onChange={() =>
                                    CuponsMealsList(
                                      EditableMenu[element].meal_bases[x]
                                        .meal_options[0].id
                                    )
                                  }
                                />
                                <label
                                  class="custom-control-label"
                                  for={
                                    EditableMenu[element].meal_bases[x].name +
                                    EditableMenu[element].meal_bases[x].id +
                                    EditableMenu[element].meal_bases[x].name
                                  }
                                >
                                  {EditableMenu[element].meal_bases[x].name}
                                </label>
                              </div>
                            );
                          } else {
                            {
                              return (
                                <div className="my-2">
                                  {Object.keys(
                                    EditableMenu[element].meal_bases[x]
                                      .meal_options
                                  ).map((y) => (
                                    <div class="custom-control custom-checkbox mr-sm-2 ">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id={
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].name +
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].id +
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].name
                                        }
                                        onChange={() =>
                                          CuponsMealsList(
                                            EditableMenu[element].meal_bases[x]
                                              .meal_options[y].id
                                          )
                                        }
                                      />
                                      <label
                                        class="custom-control-label"
                                        for={
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].name +
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].id +
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].name
                                        }
                                      >
                                        <span>
                                          {
                                            EditableMenu[element].meal_bases[x]
                                              .name
                                          }
                                          {" - "}
                                        </span>

                                        {
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[y].name
                                        }
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              );
                            }
                          }
                        }
                      )}
                    </>
                  ))}
                  <div className="d-block d-lg-none mt-5">
                    <button
                      type="button"
                      class="btn btn-success mt-3 d-block d-lg-none"
                      onClick={() => addCoupon()}
                      disabled={!Active}
                    >
                      {" "}
                      {Text}
                    </button>
                    <p className="mb-5">{ComunicateText}</p>{" "}
                    <div className="col-12 mx-0 px-0 my-5 text-left">
                      <h3 className="upper Bold">Aktywne kupony</h3>
                    </div>
                    {Cupons !== null
                      ? Object.keys(Cupons).map((e) => {
                          return (
                            <Cupon func={removeCupons} element={Cupons[e]} />
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </>
          </div>{" "}
        </div>
      </>
    );
  } else {
    return "";
  }
}
