import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
export default function CategoryONOFF({ id, is_active, ID }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [NOOFF, setNOOFF] = useState(null);
  const [ButtonType, setButtonType] = useState("btn-danger");
  useEffect(() => {
    if (is_active) {
      setNOOFF("Off");
      setButtonType("btn-danger");
    } else if (!is_active) {
      setNOOFF("On");
      setButtonType("btn-success");
    }
  }, []);

  const [Active, setActive] = useState(true);
  const OnOFF = (id) => {
    setActive(false);
    if (NOOFF === "Off" && Menu !== null) {
      let index = Menu.meal_categories[ID].meal_bases.findIndex(function (
        meal_base
      ) {
        return meal_base.id == id;
      });
      let x = Menu.meal_categories[ID].meal_bases[index];
      //console.log(Menu);
      //console.log(ID);
      //console.log(id);
      x.is_active = false;

      axios
        .put(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_options`,
          x,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200 && response.statusText === "OK") {
            //console.log(response);
            setNOOFF("On");
            setButtonType("btn-success");
            setActive(true);
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
        });
    } else if (NOOFF === "On" && Menu !== null) {
      let index = Menu.meal_categories[ID].meal_bases.findIndex(function (
        meal_base
      ) {
        return meal_base.id == id;
      });
      setActive(false);
      let x = Menu.meal_categories[ID].meal_bases[index];
      x.is_active = true;
      axios
        .put(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_options`,
          x,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200 && response.statusText === "OK") {
            //console.log(response);
            setActive(true);
            setNOOFF("Off");
            setButtonType("btn-danger");
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
        });
    }
  };

  return (
    <button
      type="button"
      class={`btn px-1 py-1 fs3 ${ButtonType} ml-1`}
      onClick={() => OnOFF(id)}
      disabled={!Active}
    >
      {NOOFF}
    </button>
  );
}
