import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export default function EditSelectedMeal({
  category_id,
  meal_base_id,
  meal_option_index,
  refresh_indexes,
}) {
  const RestaurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [MealOptionName, setMealOptionName] = useState("");
  const [Size, setSize] = useState("");
  const [Kcal, setKcal] = useState("");
  const [Price, setPrice] = useState(null);
  const [IsOptionActive, setIsOptionActive] = useState(null);
  const [MealName, setMealName] = useState("");
  const [Ingreadients, setIngreadients] = useState("");
  const [PhotoUrl, setPhotoUrl] = useState("");
  const [IsPromoted, setIsPromoted] = useState(null);
  const [IsActive, setIsActive] = useState(null);

  const [MealBaseIndex, setMealBaseIndex] = useState(0);
  const [MealBase, setMealBase] = useState({});

  const [MealOptionIndex, setMealOptionIndex] = useState(0);
  const [MealOption, setMealOption] = useState({});

  const [DeleteOptionText, setDeleteOptionText] = useState("Usuń opcje");
  const dispatch = useDispatch();
  const [Category_id, setCategory_id] = useState(null);
  const [Meal_base_id, setMeal_base_id] = useState(null);
  const [Meal_option_index, setMeal_option_index] = useState(null);

  const [ZL, setZL] = useState("");
  const [GR, setGR] = useState("");
  const ZLValid = (value) => {
    let v = value.replace(/\D/, "");
    setZL(v);
    setPrice(v + "." + GR);
  };
  const GRValid = (value) => {
    let v = value.replace(/\D/, "");
    if (v.length <= 2) {
      setGR(v);
      setPrice(ZL + "." + v);
    }
  };

  useEffect(() => {
    setCategory_id(category_id);
    setMeal_base_id(meal_base_id);
    setMeal_option_index(meal_option_index);
  }, [category_id, meal_base_id, meal_option_index]);

  useEffect(() => {
    if (
      Menu !== null &&
      Category_id !== null &&
      Meal_base_id !== null &&
      Meal_option_index !== null
    ) {
      let meal_base_index = Menu.meal_categories[
        Category_id
      ].meal_bases.findIndex(function (meal_base) {
        return meal_base.id == Meal_base_id;
      });
      setMealBaseIndex(meal_base_index);
      setMealOptionIndex(Meal_option_index);
      setMealOptionName(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].name
      );
      setSize(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].size
      );
      setKcal(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].kcal
      );
      let price = Menu.meal_categories[Category_id].meal_bases[meal_base_index].meal_options[Meal_option_index].price
      setPrice(price);
      SetZL_GR(price);
      setIsOptionActive(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index].meal_options[Meal_option_index].is_active
      );
      setMealBase(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
      );
      setMealOption(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index]
      );
    }
  }, [Menu, Category_id, Meal_base_id, Meal_option_index]);

  function EditMealOption(meal_option) {
    meal_option.name = MealOptionName;
    meal_option.size = Size;
    meal_option.kcal = Kcal;
    meal_option.price = Price;
    meal_option.is_active = IsOptionActive;
    // not implemented on frontend yet
    //menu[Category_id][index].menu_position = MenuPosition

    return meal_option;
  }

  function SaveMealOption(meal_option) {
    setMealOption(meal_option);
    Menu.meal_categories[Category_id].meal_bases[MealBaseIndex].meal_options[
      Meal_option_index
    ] = meal_option;
  }

  function DeleteMealOption() {
    Menu.meal_categories[Category_id].meal_bases[
      MealBaseIndex
    ].meal_options.splice(MealOptionIndex, 1);
    refresh_indexes(MealBaseIndex);
  }

  function SetZL_GR(price) {
    if(price!==null){
    let zl_gr = String(price).split('.');
    setZL(zl_gr[0]);
    if(zl_gr[1]===undefined){
      setGR('');
    }else{
      setGR(zl_gr[1]);
    }
  
  }}

  return (
    <div className="row  border-bottom py-4">
      <div className="col-6 px-2 mb-2">
        <input
          className="w-100 form-control"
          value={MealOptionName}
          placeholder="Nazwa"
          onChange={(e) => {
            setMealOptionName(e.target.value);
          }}
          onBlur={(e) => {
            SaveMealOption(EditMealOption(MealOption));
          }}
        />
      </div>
      <div className="col-6 px-2 mb-2">
        <div className="input-group w-50 float-left">
          {" "}
          <input
            type="text"
            className="w-100 form-control"
            placeholder="zł"
            value={ZL}
            onChange={(e) => {
              ZLValid(e.target.value);
            }}
            onBlur={(e) => {
              SaveMealOption(EditMealOption(MealOption));
            }}
          />{" "}
        </div>
        <div className="input-group w-50 float-left">
          {" "}
          <input
            type="text"
            className="w-100 form-control"
            placeholder="gr"
            value={GR}
            onChange={(e) => {
              GRValid(e.target.value);
            }}
            onBlur={(e) => {
              SaveMealOption(EditMealOption(MealOption));
            }}
          />{" "}
        </div>
      </div>
      <div className="col-6 px-2 mb-2">
        <input
          class="form-control"
          value={Size}
          placeholder="Opcja/rozmiar"
          onChange={(e) => {
            setSize(e.target.value);
          }}
          onBlur={(e) => {
            SaveMealOption(EditMealOption(MealOption));
          }}
        />
      </div>
      <div className="col-6 px-2 mb-2">
        {" "}
        <input
          class="form-control"
          value={Kcal}
          placeholder="kcal"
          onChange={(e) => {
            setKcal(e.target.value);
          }}
          onBlur={(e) => {
            SaveMealOption(EditMealOption(MealOption));
          }}
        />
      </div>

      <div className=" col-12 px-2 mb-2">
        <div class="custom-control mt-3 custom-checkbox mr-sm-2">
          <input
            type="checkbox"
            class="custom-control-input"
            id={Meal_option_index}
            checked={IsOptionActive}
            onChange={(e) => setIsOptionActive(e.target.checked)}
            onBlur={(e) => {
              SaveMealOption(EditMealOption(MealOption));
            }}
          />
          <label class="custom-control-label" for={Meal_option_index}>
            {" "}
            Aktywne{" "}
          </label>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-success my-1 mx-2"
        onClick={() => DeleteMealOption()}
      >
        {DeleteOptionText}
      </button>
    </div>
  );
}
