import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Meals from "./Meals";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { ClearAditionsCategory } from "../store/MenuReducer";
import { useDispatch } from "react-redux";
import CategoryONOFF from "./CategoryONOFF";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import CustomToggle from "./CustomToggle";
export function List({ history }) {
  const dispatch = useDispatch();
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const [EditableMenu, setEditableMenu] = useState(null);
  useEffect(() => {
    if (Menu !== null) {
      setEditableMenu(Menu.meal_categories);
      // console.log("ooocholereaaaa");
    }
  }, [Menu]);
  const [Active, setActive] = useState(true);

  const removeCategory = (id) => {
    setActive(false);
    axios
      .delete(
        `https://s1.flexmenu.pl/${ResteurantCode}/meal_category/${id}`, 
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
       // console.log(response.data);
        if (response.status === 204) {
         // console.log(response);
          window.location.reload();
          setActive(true);
        }
      })
      .catch((err) => {
        setActive(true);
      //  console.log(err.response);
      });
  };

  const [Display, setDisplay] = useState("none");
  const showRemoveCategory = () => {
    setDisplay("block");
  };
  const cancelRemoveCategory = () => {
    setDisplay("none");
  };

  const redirectEditCategory = (id) => {
    if (ClearAditionsCategory({}, dispatch)) {
      history.push(`/menu/editmenu/editcategory/${id}`);
    }
  };
  if (EditableMenu !== null) {
    return (
      <div className="row w-100 px-0 mx-0 position-relative">
        {Object.keys(EditableMenu).map((element) => (
          <>
            <div className="col-12 mt-3 px-0 mx-0">
              <span className="Bold upper ml-2">
                {EditableMenu[element].name}
              </span>
            </div>

            <Accordion defaultActiveKey="1" className="w-100 my-0">
              <div className="col-12 mt-0 px-0 mx-0 pb-2 border-bottom">
                <CategoryONOFF
                  id={EditableMenu[element].id}
                  name={EditableMenu[element].name}
                  is_active={EditableMenu[element].is_active}
                />
                <button
                  type="button"
                  class="btn px-1 py-1 fs3 btn-primary ml-1 d-none d-lg-inline"
                  onClick={() => redirectEditCategory(EditableMenu[element].id)}
                >
                  Edytuj
                </button>
                <button
                  type="button"
                  class="btn px-1 py-1 fs3 btn-danger ml-1"
                  onClick={() => showRemoveCategory()}
                >
                  Usuń
                </button>
                <CustomToggle 
                  eventKey="0" 
                />
              </div>

              <Card className="w-100 border-0 px-0">
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="w-100 border-0 px-0">
                    <Meals
                      meals={EditableMenu[element].meal_bases}
                      kategoryID={element}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <div
              className="col-12 position-absolute popup"
              style={{
                display: Display,
              }}
            >
              <div className="row py-5 px-0 mx-0">
                <div className="col-12 mb-4 px-0 mx-0">
                  <p className="text-center Bold h5">
                    Czy chcesz usunąć wybraną kategorię?
                  </p>
                </div>
                <div className="col-6 text-center">
                  {" "}
                  <button
                    type="button"
                    class="btn btn-secondary w-100"
                    onClick={() => cancelRemoveCategory()}
                  >
                    Anuluj
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    onClick={() => removeCategory(EditableMenu[element].id)}
                  >
                    Usuń
                  </button>{" "}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  } else {
    return "";
  }
}
export default withRouter(List);
