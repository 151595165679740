import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import CostumToggleQR from "./CostumToggleQR";
import axios from "axios";
const Delivery = () => {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const URL = useSelector((e) => e.UserReducer.url);
  const QRURL = useSelector((e) => e.UserReducer.qrurl);
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj kod");
  const [ComunicateText, setComunicateText] = useState("");
  const [Delivery, setDelivery] = useState(null);
  useEffect(() => {
    getDeliveryPlaces();
  }, []);
  useEffect(() => {
    getDeliveryPlaces();
  }, [Active]);
  const getDeliveryPlaces = async () => {
    await axios
      .get(`${URL}/get_delivery_places.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data);
        setDelivery(response.data.places);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [City, setCity] = useState("");
  const [FreeAbove, setFreeAbove] = useState("");
  const [Price, setPrice] = useState("");
  const ValidCity = (Value) => {
    setCity(Value);
    setComunicateText("");
  };
  const ValidPrice = (Value) => {
    setPrice(Value);
    // //console.log(Value);
  };
  const ValidFreeAbove = (Value) => {
    setFreeAbove(Value);
    ////console.log(Value);
  };
  const removeCity = (id) => {
    ////console.log(id);
    axios
      .delete(
        `https://s1.flexmenu.pl/${ResteurantCode}/delivery_place/${id}`
      )
      .then((response) => {
        ////console.log(response);
        if (response.status === 204 && response.statusText === "No Content") {
            console.log(response);
          getDeliveryPlaces();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
      setComunicateText("");
  };
  const addDelivery = () => {
    if (City !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        name: City,
        delivery_price: 0,
        free_above: 0,
        is_place: false,
      };
      ////console.log(json);
      axios
        .post(
          `https://s1.flexmenu.pl/${ResteurantCode}/delivery_places`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          // //console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //  //console.log(response);

            setActive(true);
            setText("Dodaj strefę");
            setComunicateText("Dodano! Możesz teraz dodać kolejną strefę");
            getDeliveryPlaces();
            setCity("");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      setComunicateText("Uzupełnij wszytkie pola");
    }
  };
  const downloadQRCode = (id) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("QR_code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `Strefa_${id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Bezkontaktowe menu</h1>
                <h6>Przemyśl odpowiednie nazewnictwo Twoich kodów QR, ponieważ później będą one wyświetlane na zamówieniu, po to aby wiedzieć gdzie je dostarczyć.</h6>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-5 offset-1">
                <label>Stwórz kod QR</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    value={City}
                    id="basic-url"
                    onChange={(e) => {
                      ValidCity(e.target.value);
                    }}
                    aria-describedby="basic-addon3"
                    placeholder="Nazwa kodu"
                  />
                </div>

                <button
                  type="button"
                  class="btn btn-success mt-3"
                  onClick={() => addDelivery()}
                  disabled={!Active}
                >
                  {Text}
                </button>
                <p>{ComunicateText}</p>
              </div>
              <div className="col-6 ">
                <div className="row">
                  <div className="col-2 mb-3"></div>
                  <div className="col-6 Bold mb-3 px-0">Twoje kody</div>
                  <div className="col-2 mb-3"></div>
                  <div className="col-2 mb-3"></div>
                  {Delivery !== null
                    ? Object.keys(Delivery).map((element) => {
                      if (!Delivery[element].is_place) {
                        return (
                          <>
                            <Accordion
                              defaultActiveKey="1"
                              className="w-100 my-0"
                            >
                              <div className="row">
                                <div className="col-2 mb-3">
                   
                                </div>
                                <div className="col-6 my-2 Bold d-flex align-items-center">
                                  {Delivery[element].name}
                                </div>

                                <div className="col-4 my-2">
                                      <button
                                  type="button"
                                  class="btn btn btn-primary  py-2"
                                           onClick={() => downloadQRCode(Delivery[element].id)}
                                           >
                                           Pobierz
                                          </button>
                                          <button
                                    type="button"
                                    class="btn btn-outline-danger border-0 px-3 py-2"
                                    onClick={() =>
                                      removeCity(Delivery[element].id)
                                    }
                                  >
                                    X
                                    </button>
                                </div>
                              </div>
                              <div className="col-12 my-2 text-center">
                                {" "}
                                <Card className="w-100 border-0 px-0">
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body className="w-100 border-0 px-0">
                                      <QRCode
                                        value={QRURL + '/qr/1/' + Delivery[element].id}
                                        id="QR_code"
                                      />
                                    
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </div>
                         
                            </Accordion>
                          </>
                        );
                      }
                    })
                    : ""}
                </div>
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
};

export default Delivery;
