import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import { useSelector } from "react-redux";
import OptionsAdditions from "./OptionsAdditions";
import Addition from "./Addition";
import { cleanup } from "@testing-library/react";
export default function AddAdditions() {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  useEffect(() => {
    getCategories();
    getAdditionGroup();
  }, []);
  const [Categories, setCategories] = useState([]);
  const getCategories = async () => {
    await axios
      .get(`https://s1.flexmenu.pl/${ResteurantCode}/meal_categories`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        setCategories(response.data);
      })

      .catch((err) => {
        //  //console.log(err.response);
      });
  };
  const [AdditionGroup, setAdditionGroup] = useState([]);
  const getAdditionGroup = async () => {
    await axios
      .get(
        `https://s1.flexmenu.pl/${ResteurantCode}/addition_categories`,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response.data);
        setAdditionGroup(response.data);
      })

      .catch((err) => {
        //  //console.log(err.response);
      });
  };
  const removeAdditions = (element) => {
    //console.log(element);
    axios
      .delete(
        `https://s1.flexmenu.pl/${ResteurantCode}/addition_category/${element}`, // '1' jest wartościa nie uzywaną (musi być bo dawid nie umie programować)

        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          getAdditionGroup();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [Name, setName] = useState("");
  const [ID, setID] = useState(1);
  const [OptionsList, setOptionsList] = useState({
    1: {id:1, name: null, price: 0 },
  });
  const addOpt = () => {
    let opt = OptionsList;
    var d = new Date();
    var n = d.getTime();
    opt[n] = {id:n, name: null, price: null };
    setID(Object.keys(opt).length);
    setOptionsList(opt);
    console.log(opt);
  };
  const removeOpt = (id) => {
    let opt = OptionsList;
    if (Object.keys(opt).length > 1) {
      delete opt[id];
      setID(Object.keys(opt).length);
      setOptionsList(opt);
    }

  console.log(opt);
  };
  const set = (id, value) => {
    // //console.log(id);
    let opt = OptionsList;
    opt[id] = value;
    setOptionsList(opt);
  console.log(opt);
  setComunicateText('')
  };
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz dodatki");
  const [ComunicateText, setComunicateText] = useState("");
  const ValidName = (value) => {
    setName(value);
    setComunicateText('')
  };
  const checkOptionsList = (value) => {
    let flag = true;
    // //console.log(Object.keys(value).length);
    Object.keys(value).map((element) => {
      if (
        value[element].name !== null &&
        value[element].price !== null &&
        !Number.isNaN(parseFloat(value[element].price)) &&
        value[element].name !== ""
      ) {
        //  //console.log("mamy to");
      } else {
        flag = false;
        // //console.log("mnie mamy tego");
      }
    });

    return flag;
  };
  const clean=()=>{
  setOptionsList({
    1: {id:1, name: null, price: 0 },
  });
  setName('')
  setID(999)
  console.log(OptionsList)
  }
  const addMeal = () => {
    if (
      Name !== "" &&
      checkOptionsList(OptionsList) &&
      Object.keys(toCategory).length > 0
    ) {
      setActive(false);
      setText("Czekaj");
      let json = {
        meal_categories: toCategory,
        name: Name,
        additions: OptionsList,
      };
      //  //console.log(json);
      axios
        .post(
          `https://s1.flexmenu.pl/${ResteurantCode}/additions`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          ////console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            // //console.log(response);
            //clear();
            setActive(true);
            setText("Dodaj dodatki");
            setComunicateText("Zestaw dodatków został dodany");
            getAdditionGroup();
            setOptionsList({});
            clean();
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setComunicateText("Coś poszło nie tak...Spróbuj ponownie");
        });
    } else {
      setComunicateText(
        "Uzupełnij wszytkie wymagane pola i przypisz dodatki do kategorii"
      );
      ////console.log("nie");
    }
  };
  const [toCategory, settoCategory] = useState({});
  const addtoCategory = (element) => {
    let arr = toCategory;
    if (arr[element.id] === undefined) {
      arr[element.id] = { meal_category: element.id };
    } else {
      delete arr[element.id];
    }
    settoCategory(arr);
    setComunicateText('')
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div className="row">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Twoje zestawy</h1>
            </div>
          </div>

          <div className="row mx-0 px-0">
            <div className="col-4 offset-1 my-5 ">
              <div class="input-group mb-3 px-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nazwa dodatków"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={Name}
                  onChange={(e) => {
                    ValidName(e.target.value);
                  }}
                />
              </div>{" "}
              <div class="input-group mb-3">
                {Object.keys(OptionsList).map((element) => (
                  <OptionsAdditions id={OptionsList[element].id} func={set} func2={removeOpt} />
                ))}{" "}
              </div>
              <div class="input-group mb-3">
                <div className="col-6 px-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => addOpt()}
                  >
                    + dodatek
                  </button>
                </div>
         
              </div>
              <button
                type="button"
                class="btn btn-success mx-2"
                onClick={() => addMeal()}
                disabled={!Active}
              >
                {Text}
              </button>
              <p class="mx-2 mb-5">{ComunicateText}</p>
              {Object.keys(AdditionGroup).map((element) => (
                <Addition e={AdditionGroup[element]} func={removeAdditions} />
              ))}{" "}
            </div>
            <div className="col-4 offset-1 my-5 ">
              <p>Przypisz do:</p>
              {Categories.map((element) => (
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={element.id}
                    onClick={() => addtoCategory(element)}
                  />
                  <label class="custom-control-label" for={element.id}>
                    {element.name}
                  </label>
                </div>
              ))}
            </div>{" "}
          </div>
        </div>{" "}
      </div>
    </>
  );
}
