import React, { useEffect, useState } from "react";
import Header from "./Header";
import FileBase64 from "react-file-base64";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
export default function Style() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);

  const [FileText, setFileText] = useState(null);
  const [FileName, setFileName] = useState(null);
  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name.replace(/\s+/g, ""));
  };
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Base_font_color, setBase_font_color] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
      setBase_font_color(UserInfo.colors.base_font_color);
    }
  }, [UserInfo]);

  const setWebsiteData = () => {
    let json = {};
    if (FileName !== null) {
      json = {
        colors: {
          additions_font_color: Additions_font_color,
          additions_start_color: Additions_start_color,
          additions_stop_color: Additions_stop_color,
          base_font_color: Base_font_color,
        },
        img: {
          background: {
            photo_url: "/img/restaurant/" + FileName,
            encoded_photo: FileText,
          },
        },
      };
    } else if (FileName === null) {
      json = {
        colors: {
          additions_font_color: Additions_font_color,
          additions_start_color: Additions_start_color,
          additions_stop_color: Additions_stop_color,
          base_font_color: Base_font_color,
        },
      };
    }
    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Wygląd</h1>
              </div>
            </div>
            <div className="row mx-0 px-0">
              <div className="col-4 px-2 mx-0">
                <label className="mb-0">Początkowy kolor gradientu</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                
                    placeholder=""
                    value={Additions_start_color}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAdditions_start_color(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Końcowy kolor gradientu</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
              
                    value={Additions_stop_color}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAdditions_stop_color(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Wyróżniony kolor czcionki</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                
                    value={Additions_font_color}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setAdditions_font_color(e.target.value);
                    }}
                  />
                </div>
                <label className="mb-0">Podstawowy kolor czcionki</label>
                <div class="input-group mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
               
                    value={Base_font_color}
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={(e) => {
                      setBase_font_color(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-12 px-2 mx-0">

                <label className="mb-0">Zdjęcie w tle</label>

                <div class="input-group mb-4">
                  <FileBase64 multiple={false} onDone={File.bind()} />
                </div>
                <p className="mb-0">plik powinien być w formacie PNG lub JPG</p>
<p className="mb-0">plik powien być w rozdzielczności FHD 1920 na 1080 px </p>
<p className="mb-3">plik nie powien ważyć wiecej niz 5m </p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-success mx-2 mt-3"
              onClick={() => setWebsiteData()}
              disabled={!Active}
            >
              {Text}
            </button>
            <p className=" mx-2">{ComunicateText}</p>
          </>
        </div>{" "}
      </div>
    </>
  );
}
