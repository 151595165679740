import React, { useState, useEffect } from "react";
import Header from "./Header";
import PushCampainMenagment from "./PushCampainMenagment";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { useSelector } from "react-redux";
const PushNotifi = () => {
    const URL = useSelector((e) => e.UserReducer.url);
    const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
    const [AllPush, setAllPush] = useState([]);
    const [Active, setActive] = useState(true);
    const [ComunicateText, setComunicateText] = useState("");
    const [Name, setName] = useState("");
    const [Image, setImage] = useState("");
    const [ImageName, setImageName] = useState("");
    const [Subject, setSubject] = useState("");
    const [Text, setText] = useState("");
    useEffect(() => {
      get_all_push_campain();
    }, []);
    const get_all_push_campain = () => {
      axios
        .get(`${URL}/notifications/get_notifications_for_admin.php`, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          console.log(response);
          setAllPush(response.data);
          console.log(response.data)
        })
  
        .catch((err) => {
          console.log(err);
        });
    };
    const addImg = ()=>{
      if (Text !== "" && Name !== "" && Subject !== "" && Image !== "" && ImageName !== "" ) {
              axios
              .post(
                `${URL}/add_push_image.php`,
                {
                  photo_url: "/img/push_notification/" + ImageName,
                  encoded_photo: Image,
           
                },
                {
                  "Content-Type": "application/json",
                }
              )
              .then((response) => {
                console.log(response);
                if (response.status === 200 && response.data === "OK") {
            
                  createPush();
                setComunicateText("Utorzono nową kampanię email");
                } else {
             
              setComunicateText("Coś poszło nie tak, spróbuj ponownie");
                }
              })
      
              .catch((err) => {
                
                setComunicateText("Coś poszło nie tak, spróbuj ponownie");
                //console.log(err.response);
              });
          }else{
            if(Text !== "" && Name !== "" && Subject !== "" && Image === "" && ImageName === ""){
              createPush()
            }else{
              setComunicateText("Uzupełnij wszytkie pola");
            }
       
          }
    }
    const createPush = () => {
      let json={}
      let date = new Date();
      let date_time = date.getTime();
    if(Image !== "" && ImageName !== ""){
   json={
    name: Name,
    title: Subject,
    body: Text,
    image:URL+"/img/push_notification/" + ImageName,
    sentTime: date_time,
  }
    }else{
      json={
        name: Name,
        title: Subject,
        body: Text,
        sentTime: date_time,
      }
    }
        axios
          .post(
            `${URL}/notifications/save_notification.php`,
            json,
            {
              "Content-Type": "application/json",
            }
          )
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              get_all_push_campain();
           
              setComunicateText("Utworzono nowe powiadomienie");
            } else {
         
              setComunicateText("Coś poszło nie tak, spróbuj ponownie");
            }
          })
  
          .catch((err) => {
            
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
            console.log(err.response);
          });
     
    };
    const File = (files) => {
      setImage(files.base64);
      setImageName(files.name.replace(/\s+/g, ""));
    };
    return (
        <>
          <Header />
          <div
            className="container-fluid mine-background "
            style={{
              backgroundImage: `url(${BackgroundImage})`,
              minHeight: window.innerHeight,
              paddingTop: "70px",
            }}
          >
            <div
              className="container display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="row mx-0 px-0">
                <div className="col-12 my-5 text-center">
                  <h1 className="upper Bold">Wyśli powiadomienie na aplikacje</h1>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-md-4 col-12">
                  <div class="input-group mb-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nazwa kampani"
                      value={Name}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input-group mb-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nagłówek"
                      value={Subject}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input-group mb-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Treść"
                      value={Text}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input-group mb-4">
                  <FileBase64 multiple={false} onDone={File.bind()} />
                  </div>
              
                </div>
                <div className="col-12">
           
                  <button
                    type="button"
                    class="btn btn-success mt-3 "
                    disabled={!Active}
                    onClick={addImg}
                  >
                    Stwórz kampanię
                  </button>
                  <p>{ComunicateText}</p>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-12 my-5 text-center">
                  <h1 className="upper Bold">Twoje powiadomiania w aplikacji</h1>
                </div>
              </div>
              <div className="row mx-0 px-0">
               {
               AllPush.map((element) => {
                 if(element.type=="0"){
                   return  <PushCampainMenagment e={element} func={get_all_push_campain} />
                 }
               
})
               }
              </div>
            </div>{" "}
          </div>
        </>
      );
}

export default PushNotifi;
