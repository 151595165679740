import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
function LoginPanel({ history }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const [Login, setLogin] = useState("");
  const [Pass, setPass] = useState("");
  const [LoginState, setLoginState] = useState("");
  const ValidLogin = (e) => {
    setLogin(e.target.value);
  };
  const ValidPass = (e) => {
    setPass(e.target.value);
  };
  const Submit = () => {
    axios
      .post(
        `https://s1.flexmenu.pl/${ResteurantCode}/login`,
        {
          username: Login,
          password: Pass,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        // console.log(response.data);
        setLoginState("Zalogowano");
        Cookies.set("IsLoggedIn", true);
        history.push("/");
      })

      .catch((err) => {
       // console.log(err.response);
        setLoginState("Błąd! Login lub hasło są nieprawidłowe");
      });
  };
  return (
    <div className="container-fluid text-center pt-5">
      <div className="row">
        <div className="col-lg-2 col-12 offset-lg-5">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Login</span>
            </div>

            <input
              type="text"
              aria-label="Last name"
              value={Login}
              onChange={(e) => ValidLogin(e)}
              class="form-control"
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Hasło</span>
            </div>

            <input
              type="password"
              aria-label="Last name"
              value={Pass}
              onChange={(e) => ValidPass(e)}
              class="form-control"
            />
          </div>
          <button type="button" onClick={Submit} class="btn btn-primary">
            Zaloguj
          </button>
          <p>{LoginState}</p>
        </div>
      </div>
    </div>
  );
}
export default withRouter(LoginPanel);
