import React from "react";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
export function Menu({ history }) {
  const redirectAddMeal = () => {
    history.push("/menu/addmeal");
  };
  const redirectAddCategory = () => {
    history.push("/menu/addcategory");
  };
  const redirectAddAdditions = () => {
    history.push("/menu/addadditions");
  };
  const redirectEditMenu = () => {
    history.push("/menu/editmenu");
  };
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <>
            <div className="row">
              <div className="col-12 my-5 text-center">
                <h1 className="upper Bold">Moje menu</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button "
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectAddMeal}
                >
                  Dodaj Danie
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectAddCategory}
                >
                  Dodaj kategorie
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectEditMenu}
                >
                  Edytuj Menu
                </button>
              </div>
              <div className="col-md-6 my-3 text-center">
                <button
                  type="button"
                  className="w-75 py-3 upper Bold btn btn-warning"
                  onClick={redirectAddAdditions}
                >
                  Dodatki/zestawy
                </button>
              </div>
            </div>
          </>
        </div>{" "}
      </div>
    </>
  );
}
export default withRouter(Menu);
