import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetSMSLeft, SetpricePerSMS, Setsender } from "../store/UserReducer";
import round from "../Functions/round";
import sha256 from "../Functions/sha256";
import SMSCampainMenagment from "./SMSCampainMenagment";
const SMS = () => {
  const URL = useSelector((e) => e.UserReducer.url);
  const IS_SMS_ACTIVE = useSelector((e) => e.UserReducer.is_sms_active);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const PricePerSMS = useSelector((e) => e.UserReducer.pricePerSMS);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const [Active, setActive] = useState(true);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const [ComunicateText, setComunicateText] = useState("");
  const [Display, setDisplay] = useState("none");
  const [NumbersLength, setNumbersLength] = useState(100);
  const [SMSTXT, setSMSTXT] = useState("");
  const [Price, setPrice] = useState(0.15);
  const [AllNumbers, setAllNumbers] = useState("");
  const [Name, setName] = useState("");
  const RestaurantCode = useSelector(
    (e) => e.UserReducer.allData.restaurant_info.code
  );
  const RestaurantName = useSelector(
    (e) => e.UserReducer.allData.editable_texts.restaurant_name
  );
  const RestaurantEmail = useSelector(
    (e) => e.UserReducer.allData.editable_texts.restaurant_email
  );
  const [ShopID, setShopID] = useState("705218");
  const [PIN, setPIN] = useState("qWgJDx6ulKzbPZImX7cQqCpJR5RPEuxW");
  const [CHK, setCHK] = useState(null);
  const BackURL = window.location.href;
  const Currency = "PLN";
  const Lang = "pl";
  const ChannelGroups = "K,T,M,I";
  const Type = "0";
  const IgnoreLastPaymentChannel = 0;
  const [Amount, setAmount] = useState(0);
  const [AmountShow, setAmountShow] = useState(0);
  const [Control, setControl] = useState(null);
  const [AllCampaigns, setAllCampaigns] = useState([]);
  const [Package, setPackage] = useState(0);
  const [Description, setDescription] = useState("");
  const [URLC, setURLC] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    get_costumer_data();
    get_sms_campain();
  }, []);
  const shaCreator = (ascii) => {
    let chk = null;
    //console.log(ascii);
    if ((chk = sha256(ascii))) {
      //console.log(chk);
      return chk;
    }
    //console.log(chk);
  };
  const sms_left = () => {
    axios
      .get(`https://s1.flexmenu.pl/${RestaurantCode}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const get_costumer_data = () => {
    axios
      .get(`${URL}/sqlite/get_marketing_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        setNumbersLength(response.data.length);
        setAllNumbers(response.data);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const get_sms_campain = () => {
    axios
      .get(`${URL}/sqlite/get_sms_campaigns.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        setAllCampaigns([]);
        setAllCampaigns(response.data);
        //console.log(response);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  const showSendSMS = () => {
    setDisplay("block");
  };
  const cancelSendEmail = () => {
    setDisplay("none");
  };
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    ////console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  const createSMSCampaing = () => {
    if (SMSTXT !== "" && Name !== "") {
      axios
        .post(
          `${URL}/sqlite/create_sms_campaign.php`,
          {
            name: Name,
            text: SMSTXT,
            cost: Amount.toFixed(2),
            recivers_number: AllNumbers,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          if (response.status === 200) {
            //console.log(response);
            get_sms_campain();
            setDisplay("none");
            setComunicateText("Utorzono nową kampanię SMS");
            setName('')
            setSMSTXT('')
          } else {
            setDisplay("none");
            setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          }
        })

        .catch((err) => {
          setDisplay("none");
          setComunicateText("Coś poszło nie tak, spróbuj ponownie");
          //console.log(err.response);
        });
    }
    {
      setDisplay("none");
      setComunicateText("Wpisz treść SMS i nazwę kampani");
    }
  };
  String.prototype.escapeDiacritics = function () {
    return this.replace(/ą/g, "a")
      .replace(/Ą/g, "A")
      .replace(/ć/g, "c")
      .replace(/Ć/g, "C")
      .replace(/ę/g, "e")
      .replace(/Ę/g, "E")
      .replace(/ł/g, "l")
      .replace(/Ł/g, "L")
      .replace(/ń/g, "n")
      .replace(/Ń/g, "N")
      .replace(/ó/g, "o")
      .replace(/Ó/g, "O")
      .replace(/ś/g, "s")
      .replace(/Ś/g, "S")
      .replace(/ż/g, "z")
      .replace(/Ż/g, "Z")
      .replace(/ź/g, "z")
      .replace(/Ź/g, "Z");
  };
  const [Quantity, setQuantity] = useState();
  const setPayment = async (value) => {
    setTXT("");
    let amount = 0;
    if (value !== "Wybierz pakiet SMS") {
      amount = round(value * PricePerSMS * 1.23);
      setAmountShow(amount);
      amount = round(amount / 0.985);
      setAmount(amount);
      setQuantity(value);
    } else {
      setAmount(0);
      setAmountShow(0);
      amount = 0;
      setQuantity(0);
    }
    let control = random();
    setControl(control);
    let description = RestaurantCode + "-" + RestaurantName.escapeDiacritics();
    setDescription(description);
    let urlc = `https://s1.flexmenu.pl/${RestaurantCode}/sms_payment_confirm`;
    setURLC(urlc);
    let chk = await shaCreator(
      PIN +
        Lang +
        ShopID +
        amount.toFixed(2) +
        Currency +
        description +
        control +
        ChannelGroups +
        BackURL +
        Type +
        urlc +
        RestaurantEmail +
        IgnoreLastPaymentChannel
    );
    //console.log(chk);
    setCHK(chk);
  };
  const [TXT, setTXT] = useState("");
  const [ButtonActive, setButtonActive] = useState(false);
  const submitPay = () => {
    if (Amount !== 0 && Amount !== "0") {
      setButtonActive(true);
      let json = {
        sms_quantity: Quantity,
        payment_control: Control,
        to_pay: Amount,
      };
      axios
        .post(
          `https://s1.flexmenu.pl/${RestaurantCode}/sms_order `,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200 && response.statusText === "OK" && JSON.parse(response.data).data.token!==undefined) {
            setButtonActive(false);
            window.location.replace(`https://secure.przelewy24.pl/trnRequest/${JSON.parse(response.data).data.token}`);
          }
        })

        .catch((err) => {
          setButtonActive(false);
          console.log(err.response);
        });
    } else {
      setTXT("Wybierz pakiet");
    }
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div className="row mx-0 px-0">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Stwórz kampanię SMS</h1>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-lg-4 col-md-6">
              <div class="input-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nazwa kampani "
                  value={Name}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div class="input-group mb-3">
                <textarea
                  placeholder="Treść SMS"
                  value={SMSTXT}
                  class="form-control"
                  aria-label="With textarea"
                  onChange={(e) => {
                    if (e.target.value.length <= 612) {
                      setSMSTXT(e.target.value.escapeDiacritics());
                    }
                  }}
                ></textarea>
              </div>
              <div className="col-12 mx-0 px-0">
                <p>Znaki: {SMSTXT.length}/612</p>{" "}
                <p>Ilość SMS : {Math.ceil(SMSTXT.length / 160)}</p>{" "}
                <button
                  type="button"
                  class="btn btn-success mt-3 "
                  disabled={!Active}
                  onClick={() => createSMSCampaing()}
                >
                  Swórz kampanię
                </button>
                <p>{ComunicateText}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  mb-5">
              <p>
                Pozostało do wykorzystania{" "}
                <span className="Bold">{SMSLeft} </span> SMS'ów
              </p>
              <p>
                Ilość numerów w bazie :{" "}
                <span className="Bold">{NumbersLength} </span>
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
              <div class="input-group mb-3">
                <select
                  class="custom-select"
                  id="inputGroupSelect01"
                  onChange={(e) => {
                    setPayment(e.target.value);
                  }}
                >
                  <option selected>Wybierz pakiet SMS</option>
            
                  <option value="1000">1000 SMS</option>
                  <option value="2000">2000 SMS</option>
                  <option value="3000">3000 SMS</option>
                  <option value="4000">4000 SMS</option>
                  <option value="5000">5000 SMS</option>
                  <option value="6000">6000 SMS</option>
                  <option value="7000">7000 SMS</option>
                  <option value="8000">8000 SMS</option>
                  <option value="9000">9000 SMS</option>
                  <option value="10000">10000 SMS</option>
                </select>
              </div>
              {Amount !== "0" && Amount !== 0 ? (
                <p>Do zapłaty: {AmountShow.toFixed(2)} zł</p>
              ) : (
                ""
              )}
{
  IS_SMS_ACTIVE===true?<button
  type="button"
  class="btn btn-success mt-3 "
  disabled={ButtonActive}
  onClick={() => {
    submitPay();
  }}
>
  Kup pakiet
</button>: 
<p className="h6">
Aby aktywowac zakup SMS skontaktuj się z dostawcą systemu FlexMenu
</p>
}
           
              <p>{TXT}</p>
              <p className="mb-0" ><a href={URL+"/documents/sms.pdf"}>Regulamin SMS</a></p>
              <p className="mb-0"><a href={URL+"/documents/agreement.pdf"}>Umowa</a></p>
              <p>{TXT}</p>
            </div>
          </div>
          <div className="row mx-0 px-0">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Twoje kampanie SMS</h1>
            </div>
          </div>
          <div className="row mx-0 px-0">
            {AllCampaigns.map((element) => (
              <SMSCampainMenagment
                e={element}
                all={AllNumbers}
                left={SMSLeft}
                nl={NumbersLength}
                func1={sms_left}
                func2={get_costumer_data}
                func3={get_sms_campain}
                sender={Sender}
              />
            ))}
          </div>
          <div
            className="col-md-6 col-12 my-5 position-absolute popup2"
            style={{
              display: Display,
            }}
          >
            <div className="row py-5 px-0 mx-0">
              <div className="col-12 mb-4 px-0 mx-0">
                <p className="text-center Bold h5">s</p>
              </div>
              <div className="col-6 text-center">
                {" "}
                <button
                  type="button"
                  class="btn btn-secondary w-100"
                  onClick={() => cancelSendEmail()}
                >
                  Anuluj
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  type="button"
                  class="btn btn-success w-100"
                  onClick={() => createSMSCampaing()}
                >
                  Wyślij
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </>
  );
};
//
export default SMS;
