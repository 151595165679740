import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Options from "./Options";
export function AddMeal({ history }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const URL = useSelector((e) => e.UserReducer.url);
  const Img = useSelector((e) => e.UserReducer.allData.img);
  const [Icone1, setIcone1] = useState("");
  const [Icone2, setIcone2] = useState("");
  const [Icone3, setIcone3] = useState("");
  const [Icone4, setIcone4] = useState("");
  const [Icone5, setIcone5] = useState("");
  const [Icone6, setIcone6] = useState("");
  useEffect(() => {
    if (Img !== undefined) {
      setIcone1(Img.icon1);
      setIcone2(Img.icon2);
      setIcone3(Img.icon3);
      setIcone4(Img.icon4);
      setIcone5(Img.icon5);
      setIcone6(Img.icon6);
    }
  }, [Img]);
  useEffect(() => {
    getCategories();
  }, []);
  const [FileText, setFileText] = useState("");
  const [FileName, setFileName] = useState("");
  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name);
  };
  const [Categories, setCategories] = useState([]);
  const [Length, setLength] = useState(1);
  const [OptionsList, setOptionsList] = useState({
    1: {id:1, name: null, price: 0, kcal: 0, size: 0 },
  });
  const set = (id, value) => {
    console.log(OptionsList);
    let opt = OptionsList;
    setOptionsList({})
    opt[id] = value;
    setOptionsList(opt);
    setLength(Object.keys(opt).length)
    //  //console.log(opt);
  };
  const addOpt = () => {
    let opt = OptionsList;
    setOptionsList({})
    let d = new Date();
    let n = d.getTime();
    opt[n] = {id:n, name: null, price: null, kcal: null, size: null };
    setOptionsList(opt);
    setLength(Object.keys(opt).length)
    console.log(opt);
    checkHowManyOptions(opt);
  };
  const removeOpt = (id) => {
 
    let opt = OptionsList;
    setOptionsList({})
    let optLength = Object.keys(opt).length;
    console.log(optLength);
    if (optLength > 1) {
      delete opt[id];
    
      setOptionsList(opt);
    }
    console.log(opt);
    setOptionsList(opt);
    checkHowManyOptions(opt);
    setLength(Object.keys(opt).length)
    //  //console.log(opt);
  };
  const [NameDisable, setNameDisable] = useState(true);
  const checkHowManyOptions = (value) => {
    if (Object.keys(value).length === 1) {
      setNameDisable(true);
    } else {
      setNameDisable(false);
    }
  };
  const getCategories = async () => {
    await axios
      .get(`https://s1.flexmenu.pl/${ResteurantCode}/meal_categories`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        setCategories(response.data);
      })

      .catch((err) => {
        //  //console.log(err.response);
      });
  };
  const [Check, setCheck] = useState(0);
  const echo = (e) => {
    if (e.target.checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };
  const [Category, setCategory] = useState(null);
  const [Name, setName] = useState("");
  const NameValid = (e) => {
    setName(e.target.value);
  };

  const [Discriptin, setDiscriptin] = useState("");
  const DescriptionValid = (e) => {
    setDiscriptin(e.target.value);
  };

  const checkOptionsList = (value) => {
    let flag = true;
    // //console.log(Object.keys(value).length);

    if (Object.keys(value).length > 1) {
      Object.keys(value).map((element) => {
        if (
          value[element].name !== null &&
          value[element].price !== null &&
          !Number.isNaN(parseFloat(value[element].price)) &&
          value[element].name !== ""
        ) {
          //  //console.log("mamy to");
        } else {
          flag = false;
          // //console.log("mnie mamy tego");
        }
      });
    } else if (Object.keys(value).length === 1) {
      Object.keys(value).map((element) => {
        if (
          value[element].price !== null &&
          !Number.isNaN(parseFloat(value[element].price))
        ) {
          // //console.log("mamy to");
        } else {
          flag = false;
          // //console.log("mnie mamy tego");
        }
      });
    }

    return flag;
  };
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj posiłek");
  const [ComunicateText, setComunicateText] = useState("");
  const clear = () => {

    setOptionsList({});
    setDiscriptin("");
    setName("");
   
    let d = new Date();
    let n = d.getTime();
    setOptionsList({
      n: {id:n, name: null, price: 0, kcal: 0, size: 0 },
    });
    setNameDisable(true);
  };
  const addMeal = () => {
    if (
      Category !== null &&
      Name !== "" &&
      Discriptin !== "" &&
      checkOptionsList(OptionsList)
    ) {
      setActive(false);
      setText("Czekaj");
      let json = {
        meal_category: Category,
        name: Name,
        ingredients: Discriptin,
        photo_url: FileName,
        photo: FileText,
        is_promoted: Check,
        options: OptionsList,
        icons: IconsArray,
      };
      //console.log(json);
      axios
        .post(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_options`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          ////console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //  //console.log(response);
            clear();
            setActive(true);
            setText("Dodaj posiłek");
            setComunicateText("Dodano! Możesz teraz dodać kolejny posiłek");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      setComunicateText("Uzupełnij wszytkie wymagane pola");
    }
  };
  const [IconsArray, setIconsArray] = useState({});
  const [Icon1, setIcon1] = useState(false);
  const [Icon2, setIcon2] = useState(false);
  const [Icon3, setIcon3] = useState(false);
  const [Icon4, setIcon4] = useState(false);
  const [Icon5, setIcon5] = useState(false);
  const [Icon6, setIcon6] = useState(false);

  const addIcon = (value) => {
    switch (value) {
      case 1: {
        if (Icon1) {
          let array = IconsArray;
          // //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[1] !== undefined) {
            delete array[1];
            setIcon1(false);
            setIconsArray(array);
            // //console.log(array);
          } else {
            delete array[1];
            setIcon1(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_fat.svg";
          let array = IconsArray;
          // //console.log(array[1]);
          if (Object.keys(array).length < 4 && array[1] === undefined) {
            array[1] = txt;
            setIconsArray(array);
            // //console.log(array);
            setIcon1(true);
          } else {
            delete array[1];
            setIcon1(false);
            setIconsArray(array);
          }
        }
        break;
      }
      case 2: {
        if (Icon2) {
          let array = IconsArray;
          // //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[2] !== undefined) {
            delete array[2];
            setIcon2(false);
            setIconsArray(array);
            // //console.log(array);
          } else {
            delete array[2];
            setIcon2(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_hot.svg";
          let array = IconsArray;
          //  //console.log(array[2]);
          if (Object.keys(array).length < 4 && array[2] === undefined) {
            array[2] = txt;
            setIconsArray(array);
            //   //console.log(array);
            setIcon2(true);
          } else {
            delete array[2];
            setIcon2(false);
            setIconsArray(array);
          }
        }
        break;
      }
      case 3: {
        if (Icon3) {
          let array = IconsArray;
          // //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[3] !== undefined) {
            delete array[3];
            setIcon3(false);
            setIconsArray(array);
            // //console.log(array);
          } else {
            delete array[3];
            setIcon3(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_lactoze.svg";
          let array = IconsArray;
          // //console.log(array[3]);
          if (Object.keys(array).length < 4 && array[3] === undefined) {
            array[3] = txt;
            setIconsArray(array);
            // //console.log(array);
            setIcon3(true);
          } else {
            delete array[3];
            setIcon3(false);
            setIconsArray(array);
          }
        }
        break;
      }
      case 4: {
        if (Icon4) {
          let array = IconsArray;
          // //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[4] !== undefined) {
            delete array[4];
            setIcon4(false);
            setIconsArray(array);
            // //console.log(array);
          } else {
            delete array[4];
            setIcon4(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_sale.svg";
          let array = IconsArray;
          //  //console.log(array[4]);
          if (Object.keys(array).length < 4 && array[4] === undefined) {
            array[4] = txt;
            setIconsArray(array);
            //  //console.log(array);
            setIcon4(true);
          } else {
            delete array[4];
            setIcon4(false);
            setIconsArray(array);
          }
        }
        break;
      }
      case 5: {
        if (Icon5) {
          let array = IconsArray;
          //  //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[5] !== undefined) {
            delete array[5];
            setIcon5(false);
            setIconsArray(array);
            //  //console.log(array);
          } else {
            delete array[5];
            setIcon5(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_sugar.svg";
          let array = IconsArray;
          //  //console.log(array[5]);
          if (Object.keys(array).length < 4 && array[5] === undefined) {
            array[5] = txt;
            setIconsArray(array);
            // //console.log(array);
            setIcon5(true);
          } else {
            delete array[5];
            setIcon5(false);
            setIconsArray(array);
          }
        }
        break;
      }
      case 6: {
        if (Icon6) {
          let array = IconsArray;
          //  //console.log(Object.keys(array).length);
          if (Object.keys(array).length < 4 && array[6] !== undefined) {
            delete array[6];
            setIcon6(false);
            setIconsArray(array);
            //  //console.log(array);
          } else {
            delete array[6];
            setIcon6(false);
            setIconsArray(array);
          }
        } else {
          let txt = "icon_vege.svg";
          let array = IconsArray;
          //  //console.log(array[6]);
          if (Object.keys(array).length < 4 && array[6] === undefined) {
            array[6] = txt;
            setIconsArray(array);
            //   //console.log(array);
            setIcon6(true);
          } else {
            delete array[6];
            setIcon6(false);
            setIconsArray(array);
          }
        }
        break;
      }
    }
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div className="row">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">tworzysz danie </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-4 offset-1 my-5">
              <div class="input-group mb-3">
                <select
                  class="custom-select"
                  id="inputGroupSelect01"
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <option value={null} selected>
                    Wybierz kategorię..
                  </option>
                  {Categories.map((element) => (
                    <option value={element.id}>{element.name}</option>
                  ))}
                </select>
              </div>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nazwa dania"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={Name}
                  onChange={(e) => {
                    NameValid(e);
                  }}
                />
              </div>
              <div class="input-group mb-3">
                <textarea
                  placeholder="Opis"
                  value={Discriptin}
                  class="form-control"
                  aria-label="With textarea"
                  onChange={(e) => {
                    DescriptionValid(e);
                  }}
                ></textarea>
              </div>
              <FileBase64 multiple={false} onDone={File.bind()} />
              <div class="custom-control mt-3 custom-checkbox mr-sm-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customControlAutosizing"
                  onChange={(e) => {
                    echo(e);
                  }}
                />
                <label
                  class="custom-control-label"
                  for="customControlAutosizing"
                >
                  Wyróżnij
                </label>
              </div>
              <div className="row w-100 mt-4">
                <div className="col-2 text-center">
                  <input
                    type="checkbox"
                    checked={Icon1}
                    onChange={() => addIcon(1)}
                  />
                  <img src={URL + Icone1} />
                </div>
                <div className="col-2 text-center">
                  {" "}
                  <input
                    type="checkbox"
                    checked={Icon2}
                    onChange={() => addIcon(2)}
                  />
                  <img src={URL + Icone2} />
                </div>
                <div className="col-2 text-center">
                  {" "}
                  <input
                    type="checkbox"
                    checked={Icon3}
                    onChange={() => addIcon(3)}
                  />
                  <img src={URL + Icone3} />
                </div>
                <div className="col-2 text-center">
                  {" "}
                  <input
                    type="checkbox"
                    checked={Icon4}
                    onChange={() => addIcon(4)}
                  />
                  <img src={URL + Icone4} />
                </div>
                <div className="col-2 text-center">
                  {" "}
                  <input
                    type="checkbox"
                    checked={Icon5}
                    onChange={() => addIcon(5)}
                  />
                  <img src={URL + Icone5} />
                </div>
                <div className="col-2 text-center">
                  {" "}
                  <input
                    type="checkbox"
                    checked={Icon6}
                    onChange={() => addIcon(6)}
                  />
                  <img src={URL + Icone6} />
                </div>
              </div>
              <button
                type="button"
                class="btn btn-success mt-5"
                onClick={() => addMeal()}
                disabled={!Active}
              >
                {Text}
              </button>
              <p>{ComunicateText}</p>
            </div>
            <div className="col-7 my-5 bg-white">
              {Object.keys(OptionsList).map((element) => (
                <Options id={OptionsList[element].id} func={set} func2={removeOpt} disabled={NameDisable} />
              ))}

              <div className="row">
                <div className="col-7 px-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => addOpt()}
                  >
                    Dodaj opcję/rozmiar
                  </button>
                </div>
         
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
export default withRouter(AddMeal);
