import React, { useState } from "react";
import Header from "./Header";
import axios from "axios";
import HeaderIconSelector from "./HeaderIconSelector";
import { useSelector } from "react-redux";
export default function AddCategory() {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const BackgroundImage = useSelector((e) => e.UserReducer.backgroundImage);
  const count = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
  ];
  const [Name, setName] = useState("");
  const [Icon, setIcon] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj kategorie");
  const [ComunicateText, setComunicateText] = useState("");
  const ValidName = (value) => {
    setName(value);
  };
  const addCategory = () => {
    if (Name !== "" && Icon !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        icon: Icon,
        name: Name,
      };
      //  console.log(json);
      axios
        .post(
          `https://s1.flexmenu.pl/${ResteurantCode}/meal_categories`,
          json,
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          //  console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //  console.log(response);
            // clear();
            setActive(true);
            setText("Dodaj posiłek");
            setComunicateText("Dodano! Możesz teraz dodać kolejny posiłek");
            setIcon("");
            setName("");
          }
        })
        .catch((err) => {
         // console.log(err.response);
        });
    } else {
      setComunicateText("Wprowadz nazwę kategori i wybierz ikonę");
    }
  };
  const sellect = (element) => {
    // console.log(element);
    setIcon(element);
  };

  return (
    <>
      <Header />
      <div
        className="container-fluid mine-background "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          minHeight: window.innerHeight,
          paddingTop: "70px",
        }}
      >
        <div
          className="container display  px-0 position-relative "
          style={{ background: "white", minHeight: window.innerHeight }}
        >
          <div className="row">
            <div className="col-12 my-5 text-center">
              <h1 className="upper Bold">Tworzysz kategorię</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-4 offset-1 my-5">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nazwa kategorii"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={Name}
                  onChange={(e) => {
                    ValidName(e.target.value);
                  }}
                />
              </div>{" "}
              <div class="input-group  align-items-center d-flex">
                <span>Wybrana ikona:</span>
                <span>
                  {" "}
                  <HeaderIconSelector icon={Icon} color="#000000" />
                </span>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-success mt-3"
                  onClick={() => addCategory()}
                  disabled={!Active}
                >
                  {Text}
                </button>
                <p>{ComunicateText}</p>
              </div>
            </div>
            <div className="col-4 offset-1 my-5">
              <div class="input-group mb-3">
                {count.map((element) => (
                  <div
                    id={element}
                    className="clicked ml-1"
                    style={{
                      width: "56px",
                      height: "56px",
                      cursor: "pointer",
                    }}
                    onClick={() => sellect(element)}
                  >
                    <HeaderIconSelector icon={element} color="#000000" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}
