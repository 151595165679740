import React, { useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import MealONOFF from "./MealONOFF";

export function Meals({ meals, kategoryID, history }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const redirectEditMeal = (id) => {
    history.push(`/menu/editmenu/editmeal/${id}/${kategoryID}`);
  };
  const [Display, setDisplay] = useState("none");

  const removeMeal = (id) => {
    //console.log(id);
    axios
      .delete(
        `https://s1.flexmenu.pl/${ResteurantCode}/meal_base/${id}`,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          window.location.reload();
          // setActive(true);
        }
      })
      .catch((err) => {
        // setActive(true);
        //console.log(err.response);
      });
  };
  return (
    <>
      {meals.map((element) => (
        <>
          <div className="col-12 my-1 px-0 mx-0 ">
            <MealONOFF
              name={element.name}
              id={element.id}
              is_active={element.is_active}
              ID={kategoryID}
            />
            <button
              type="button"
              class="btn px-1 py-1 fs3 btn-primary ml-1 d-none d-lg-inline"
              onClick={() => redirectEditMeal(element.id)}
            >
              Edytuj
            </button>
            <button
              type="button"
              class="btn px-1 py-1 fs3 btn-danger ml-1"
              onClick={() => removeMeal(element.id)}
            >
              Usuń
            </button>
            <span className="Lite ml-2 fs3">{element.name}</span>
          </div>
          <div
            className="col-12 position-absolute popup"
            style={{
              display: Display,
            }}
          ></div>
        </>
      ))}
    </>
  );
}
export default withRouter(Meals);
